.psamission {
	margin-bottom: 60px;

	&__body {
		position: relative;
		padding: 40px 40px;

		@include media($md) {
			padding: 20px 40px; } }

	&__pic {
		flex: 1 0 386px;
		max-width: 386px;

		@include media($lg) {
			flex: 1 0 230px;
			max-width: 230px;
			margin: 0 auto; } }

	&__texts {}

	&__text {

		&:not(:last-child) {
			margin-bottom: 16px; } } }
