.overview {
	margin-bottom: 60px;

	&__body {
		padding: 45px 80px; }

	&__texts {
		margin-bottom: 10px; }

	&__text {

		&:not(:last-child) {
			margin-bottom: 16px; } }

	&__title {
		margin-bottom: 10px;
		font-size: 26px;
		font-weight: 700;
		color: $c-bg; }

	ul {
		li {
			font-size: 16px !important;
			color: $c-text !important;
			text-transform: initial !important;
			font-weight: 400 !important; } } }
