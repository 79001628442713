.txt-blocks {
	font-size: 16px;
	margin-bottom: var(--marginLg);
	@include media($sm) {
		font-size: 14px; }
	p {
		font-size: 16px;
		line-height: 145%;
		color: rgba(6, 14, 65, 0.8);
		@include media($lg) {
			font-size: 14px; }
		&:not(:last-child) {
			margin-bottom: 20px; } }
	h2, h3, h4, h5, h6 {
		//color: #060E41
		font-weight: bold; }
	.h2 {
		font-size: 28px !important;
		margin-bottom: 20px;
		font-weight: 700;
		color: #060E41;
		@include media($lg) {
			font-size: 20px !important; } }
	.h3 {
		font-size: 22px !important;
		margin-bottom: 20px;
		color: #060E41;
		font-weight: 700;
		@include media($lg) {
			font-size: 20px !important; } }
	.highlight {
		margin: 50px 0;
		display: flex;
		align-items: flex-start;
		background-color: #FAFAFA;
		padding: 30px 67px 30px 52px;
		@include media($lg) {
			margin: 40px 0;
			padding: 20px 50px; }
		@include media($sm) {
			padding: 28px 23px; }
		&-info {
			width: 140px;
			padding-right: 30px;
			@include media($lg) {
				width: 115px; }
			@include media($sm) {
				width: 95px;
				padding-right: 10px; }
			.name {
				font-weight: bold;
				line-height: 140%;
				color: #060E41;
				font-size: 16px;
				margin-bottom: 10px; }

			.date {
				font-size: 14px;
				color: rgba(6, 14, 65, 0.5);
				line-height: 1.5;
				@include media($sm) {
					font-size: 12px; } } }
		&-content {
			width: calc(100% - 140px);
			color: rgba(6, 14, 65, 0.7);
			line-height: 140%;

			@include media($lg) {
				width: calc(100% - 115px);
				p {
					font-size: 14px; } }
			@include media($sm) {
				width: calc(100% - 95px); } } }
	ol {
		margin: 30px 0 50px;
		counter-reset: section;
		@include media($lg) {
			margin: 20px 0 40px; }
		li {
			padding-left: 10px;
			list-style-type: none;
			@include media($sm) {
				font-size: 14px; } }
		& > li,
		&> li ol li {
			position: relative;
			&::before {
				counter-increment: section;
				content: counters(section , ".") ".";
				margin-right: 10px;

				line-height: 145%;
				color: rgba(6, 14, 65, 0.8);
				position: absolute;
				left: 0px;
				top: 0.1em;
				@include media($sm) {
					font-size: 14px; } } }

		&.column-in-2 {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin: 30px 0 70px;
			@include media($lg) {
				margin: 30px 0 50px; }
			li {
				width: calc(50% - 11.5px);
				@include media($lg) {
					width: 100%; } } }
		&._simple {
			display: inline-grid;
			li {
				margin-right: auto; } }

		&._inline {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 22px;
			@include media($lg) {
				flex-direction: column;
				justify-content: center;
				gap: 15px; }

			li {
				min-width: 320px;
				padding: 29px;
				background-color: #F5F5F5;
				text-align: center;
				color: #1F2654;
				font-size: 16px;
				@include media($lg) {
					max-width: 320px;
					margin: 0 auto; }
				@include media($sm) {
					width: 100%;
					min-width: 100%; }

				&::before {
					position: relative;
					left: auto;
					top: auto;
					color: #1F2654;
					font-size: 16px;
					margin-right: 5px; } } }

		&:not(._noStyle) {
			li {
				padding: 20px;
				margin-bottom: 10px;
				background-color: #FAFAFA;
				padding-left: 40px;
				&::before {
					font-weight: bold;
					left: 20px;
					top: 19px; } } } }

	.txt-sourses {
		margin: 50px 0;
		@include media($lg) {
			margin: 40px 0; }
		p {
			font-weight: 350;
			color: rgba(6, 14, 65, 0.6);
			font-size: 14px;
			line-height: 145%;
			margin-bottom: 5px; }
		ol {
			list-style-type: none;
			margin: 0;
			counter-reset: section1; }

		li {
			font-weight: 350;
			color: rgba(6, 14, 65, 0.6);
			font-size: 14px;
			line-height: 145%;
			margin-bottom: 5px;
			padding-left: 15px;
			&:before {
				top: 0;
				font-size: 14px;
				counter-increment: section1;
				content: counters(section1, ".") ".";
				font-weight: 350;
				color: rgba(6, 14, 65, 0.6); }
			a {
				font-size: inherit;
				color: inherit;
				display: inline-block;
				font-weight: inherit;
				word-wrap: break-word;
				max-width: 100%; } } }

	ul {
		&._bg {
			display: flex;
			flex-wrap: wrap;
			gap: 28px;
			margin: 30px 0 70px;
			@include media($lg) {
				margin: 30px 0 60px;
				justify-content: center; }
			@include media($sm) {
				gap: 0; }
			li {
				width: calc(25% - (28px / 4 * 3));
				border-radius: 16px;
				background-color: #F1F1FB;
				padding: 30px;
				@include media($lg) {
					width: calc(33.33% - (28px / 4 * 3)); }
				@include media($sm) {
					width: 100%;
					max-width: 230px;
					margin: 0 auto 28px;
					&:last-child {
						margin-bottom: 0; } }
				img {
					width: 100% !important;
					height: auto!important;
					margin: 0 auto 15px; }
				.name {
					text-align: center;
					font-weight: bold;
					line-height: 120%;
					color: rgba(6, 14, 65, 0.8);
					display: inline-block;
					font-size: 16px;
					width: 100%; } } }
		&._xs {
			gap: 22px!important;

			li {
				width: calc(20% - (22px / 5 * 4));
				padding: 26px 29px;
				@include media($lg) {
					max-width: 190px; }
				@include media($sm) {
					max-width: 190px;
					margin: 0; }
				img {
					max-width: 130px;
					margin: 0 auto 15px; }
				.name {
					font-size: 14px;
					text-align: center;
					display: inline-block;
					width: 100%; } } }

		&:not(._noStyle) {
			margin: 20px 0;
			&._orange {
				li {
					&::before {
						background-color: #D96E14; } } }
			&._green {
				li {
					&::before {
						background-color: #258E8E; } } }
			&._azzure {
				li {
					&::before {
						background-color: #35C8C7; } } }
			li {
				position: relative;
				padding-left: 15px;
				margin-bottom: 10px;
				color: rgba(31, 38, 84, 0.8);
				font-size: 15px;
				&::before {
					content: '';
					width: 7px;
					height: 7px;
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: 0.4em;
					background-color: rgba(15, 65, 202, 1); } } }
		&._list-icon {
			display: flex;
			flex-wrap: wrap;
			padding: 40px 45px;
			background-color: #FFFFFF;
			border-radius: 20px;
			box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
			gap: 25px 10px;
			@include media($lg) {
				padding: 50px 45px; }
			@include media($sm) {
				gap: 25px;
				padding: 50px 60px; }

			li {
				width: calc(100% / 6 - (10px / 6 * 5));
				@include media($lg) {
					width: calc(100% / 4 - (10px / 4 * 3)); }
				@include media($sm) {
					width: calc(50% - 12.5px); }
				img {
					width: 50px!important;
					height: auto!important;
					margin: 0 auto 10px; }
				span {
					color: rgba(6, 14, 65, 1);
					line-height: 125%;
					text-align: center;
					font-size: 14px;
					display: inline-block; } } }
		&._list-simple {
			padding: 40px 59px;
			gap: 25px;
			border-radius: 10px;
			background: #FFFFFF;
			box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
			display: flex;
			flex-wrap: wrap;
			@include media($lg) {
				padding: 40px 55px; }
			@include media($sm) {
				padding: 45px 55px;
				gap: 15px; }
			li {
				width: calc(33.33% - (25px / 3 * 2));
				margin: 0;
				font-weight: 350;
				font-size: 13px;
				line-height: 120%;
				color: rgba(6, 14, 65, 1);
				@include media($lg) {
					width: calc(50% - 12.5px); }
				@include media($sm) {
					width: 100%;
					font-size: 16px;
					line-height: 135%; } } } }




	&__img {
		margin: 50px auto 70px;
		text-align: center;
		@include media($lg) {
			margin: 40px auto 60px; }

		img {
			height: auto!important;
			margin: 0 auto 25px!important; }
		.name {
			margin-bottom: 10px;
			color: #060E41;
			font-size: 28px;
			font-weight: bold;
			line-height: 145%;
			@include media($lg) {
				font-size: 20px; }
			@include media($sm); }
		.description {
			font-size: 14px;
			max-width: 615px;
			line-height: 145%;
			color: rgba(6, 14, 65, 0.5);
			margin: 0 auto; } }
	img {
		height: auto!important;
		margin: 70px auto;
		@include media($lg) {
			margin: 50px auto; } }

	&__solutions {
		display: flex;
		align-items: flex-start;
		margin: 60px 0 20px;
		flex-wrap: wrap;
		@include media($lg) {
			margin: 50px 0 20px; }
		&._lg {
			.txt-blocks__solutions-info {
				width: 305px;
				font-size: 36px;
				font-weight: 800;
				@include media($lg) {
					font-size: 32px;
					width: 260px; }
				@include media($sm) {
					width: 100%;
					text-align: center;
					font-size: 30px; } }
			.txt-blocks__solutions-content {
				width: calc(100% - 305px);
				@include media($lg) {
					width: calc(100% - 260px); }
				@include media($sm) {
					width: 100%; } } }

		&-info {
			width: 190px;
			padding-right: 20px;
			color: rgba(6, 14, 65, 0.8);
			font-size: 28px;
			font-weight: bold;
			line-height: 145%;
			@include media($lg) {
				width: 140px;
				font-size: 20px; }
			@include media($sm) {
				width: 100%;
				margin-bottom: 20px;
				padding-right: 0;
				line-height: 100%; } }
		&-content {
			width: calc(100% - 190px);
			@include media($lg) {
				width: calc(100% - 140px); }
			@include media($sm) {
				width: 100%; }
			p {
				color: rgba(6, 14, 65, 0.8);
				@include media($lg) {
					font-size: 14px; }
				b {
					font-weight: bold; }
				&:not(:last-child) {
					margin-bottom: 20px; } } } }

	&__colors {
		margin: 50px 0;
		@include media($lg) {
			margin: 40px 0; }
		&-item {
			margin: 0 0 25px;
			display: flex;
			flex-wrap: wrap;
			&__info {
				width: 136px;
				padding-right: 10px;
				@include media($sm) {
					width: 100%;
					display: inline-grid;
					margin-bottom: 15px;
					span {
						margin-right: auto;
						width: auto;
						min-width: 126px; } }

				span {
					display: inline-block;
					width: 100%;
					text-align: center;
					padding: 5px;
					font-size: 16px;
					font-weight: bold;
					color: #fff; } }
			&__content {
				width: calc(100% - 136px);
				padding-top: 5px;
				@include media($sm) {
					padding-top: 0;
					width: 100%; } } } }

	&__list {
		margin: 50px 0;
		@include media($lg) {
			margin: 40px 0; }
		&-item {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 37px 81px 39px;
			background-color: #F6F6F6;
			@include media($lg) {
				padding: 27px 34px 29px; }

			&:not(:last-child) {
				margin-bottom: 20px;
				@include media($lg) {
					margin-bottom: 15px; } }
			.img {
				width: 200px;
				@include media($sm) {
					width: 100%; }

				img {
					width: 100% !important;
					height: auto!important;
					margin: 0;
					@include media($sm) {
						max-width: 200px;
						margin: 0 auto 20px; } } }
			.text {
				width: calc(100% - 200px);
				padding-left: 20px;
				@include media($sm) {
					width: 100%;
					padding-left: 0; }
				.zag {
					font-size: 18px;
					margin-bottom: 10px;
					color: rgba(6, 14, 65, 0.8);
					letter-spacing: 0.02em;
					font-weight: bold;
					line-height: 145%;
					@include media($sm) {
						text-align: center; } }
				p {
					color: rgba(6, 14, 65, 0.6);
					@include media($sm) {
						font-size: 14px; } } } } }
	&__double-list {
		display: flex;
		flex-wrap: wrap;
		margin: 50px 0;
		padding: 0 20px;
		@include media($lg) {
			padding: 0;
			margin: 40px 0; }
		.item {
			padding: 5px 70px 20px;
			width: 50%;
			border-color: #9C9898;
			@include media($lg) {
				padding: 15px 30px 30px; }
			@include media($sm) {
				width: 100%;
				padding: 0 0 40px; }
			&:nth-child(odd) {
				border-right: 1px solid;
				@include media($sm) {
					border-right: 0;
					border-bottom: 1px solid;
					margin-bottom: 40px; } }
			&:last-child {
				@include media($sm) {
					padding-bottom: 0; } }
			ul {
				margin: 0; }
			li {
				@include media($lg) {
					font-size: 14px; }
				&:last-child {
					margin-bottom: 0; } }
			.zag {
				text-align: center;
				margin-bottom: 15px;
				font-size: 28px;
				font-weight: bold;
				line-height: 145%;
				@include media($lg) {
					font-size: 20px; }
				@include media($sm) {
					text-align: left; } } } }

	&__center {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 60px 0;
		width: 100%;
		a {
			display: inline-flex;
			justify-content: center; } }

	&__background {
		position: relative;
		padding: 55px 0;
		display: flex;
		flex-wrap: wrap;
		@include media($lg) {
			padding: 35px 0; }
		@include media($sm) {
			padding: 55px 0; }
		&::before {
			content: '';
			width: 100vw;
			height: 100%;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 0;
			background-color: #EAF9F9;
			z-index: -1; }
		&-info {
			width: 405px;
			padding-right: 20px;
			font-size: 46px;
			font-weight: 800;
			color: #060E41;
			line-height: 135%;
			h2 {
				font-size: inherit!important;
				margin: 0; }
			@include media($lg) {
				font-size: 36px;
				width: 255px; }
			@include media($sm) {
				font-size: 30px;
				width: 100%;
				margin-bottom: 27px;
				text-align: center;
				padding-right: 0; } }
		&-content {
			width: calc(100% - 405px);
			display: flex;
			flex-wrap: wrap;
			gap: 40px;
			@include media($lg) {
				width: calc(100% - 255px);
				gap: 20px; }
			@include media($sm) {
				padding: 0 40px;
				width: 100%;
				gap: 0 30px; } }
		&-item {
			margin-bottom: 30px;
			max-width: 124px;
			@include media($lg) {
				margin-bottom: 15px; }
			@include media($sm) {
				width: calc(50% - 15px);
				min-width: 40%; }
			.zag {
				font-size: 20px;
				line-height: 145%;
				font-weight: bold;
				color: #060E41;
				margin-bottom: 10px;
				@include media($lg) {
					font-size: 16px;
					margin-bottom: 5px; }
				@include media($sm) {
					font-size: 18px; } }
			ul {
				margin: 0;
				li {
					margin: 0;
					@include media($lg) {
						font-size: 14px!important; }
					@include media($sm) {
						font-size: 16px!important; }
					&:not(:last-child) {
						margin-bottom: 5px; } } } } } }
