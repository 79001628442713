.phones-accordion {
	$this: &;
	margin-bottom: 11px;

	&:hover {

		#{$this}__icon {

			.icon {
				fill: $c-main; } } }

	&__top {
		position: relative;
		display: inline-block;
		padding-right: 30px; }

	&__icon {
		position: absolute;
		top: -3px;
		left: 0;
		width: 15px;
		height: 23px;

		.icon {
			@include transish;
			fill: #000; } }

	&__item {
		display: block;
		padding-left: 30px;
		color: #000;
		text-decoration: none;
		font-size: 14px;
		transition: color 0.3s ease;

		&:hover {
			text-decoration: none;
			color: $c-main; } }

	&__arrow {
		position: absolute;
		top: -1px;
		right: 0;
		@include sizeblock(20px);
		@include transish;
		cursor: pointer;
		transform: rotate(180deg);

		&:hover {

			.icon {
				fill: $c-main; } }

		.icon {
			position: absolute;
			@include center;
			fill: #fff;
			width: 12px;
			height: 9px;
			@include transish; } }

	&__dropdown {
		max-height: 0;
		opacity: 0;
		visibility: hidden;
		@include transish;
		overflow: hidden;

		#{$this}__item {

			margin-top: 11px; } }

	&.open {

		#{$this}__dropdown {
			max-height: 300px;
			opacity: 1;
			visibility: visible; }

		#{$this}__arrow {
			transform: rotate(0); } } }
