.request-quote {
	margin-bottom: 60px;

	&__body {
		position: relative;
		display: flex;
		align-items: center;
		padding: 25px 50px;

		@include media($md) {
			display: block; } }

	&__content {
		margin-right: 25px;

		@include media($md) {
			margin-right: 0; } }

	&__title {
		margin-bottom: 12px;
		font-size: 46px;
		font-weight: 800;
		line-height: 1;
		color: $c-bg;

		@include media($lg) {
			font-size: 36px; } }

	&__text {}

	&__action {
		flex: 1 0 285px;
		max-width: 285px;

		@include media($md) {
			margin-top: 20px; } } }
