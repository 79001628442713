.form-group {
	position: relative;
	margin-bottom: 28px;

	&:hover {

		.form-control,
		.textarea {
			border-color: $c-main; } }

	&.has-success {

		.form-control {} }

	&.has-error {

		.form-control,
		.textarea {
			color: $c-text;
			border-color: $red; } }

	// .bv-icon

	// 	&-ok
	// 		position: absolute
	// 		bottom: 10px
	// 		right: 10px
	// 		+sizeblock(22px)
	// 		background-color: rgba(0, 206, 119, 0.05);
	// 		+circle

	// 		&:after
	// 			+pseudo
	// 			width: 9px
	// 			height: 4px
	// 			top: 6px
	// 			left: 5px
	// 			border-bottom: 3px solid $green
	// 			border-left: 3px solid $green
	// 			transform: rotate(-45deg)

	.textarea {
		display: block;
		width: 100%;
		min-height: 150px;
		padding: 12px;
		border: 1px solid #E7E7E7;
		@include transish;
		resize: none;

		&:focus {
			border-color: $c-main;
			outline: none; } }

	.form-control-feedback {
		display: none !important; }

	input.form-control + .form-control-feedback {
		display: block !important; } }


.form-control {
	position: relative;
	height: 42px;
	padding: 0;
	border-radius: 0;
	border: none;
	border-bottom: 1px solid #B9B9B9;
	font-size: 16px;
	line-height: 1;
	font-family: $font-1;
	color: $c-bg;
	@include transish();

	&:focus {
		border-color: $c-main;
		outline: none;
		box-shadow: none; }

	&:-internal-autofill-selected {
		background-color: #fff; }

	&:-webkit-autofill {
		box-shadow: 0 0 0 30px #fff inset !important; } }

.field-label {
	display: block;
	min-width: 200px;
	margin-bottom: 5px;
	padding-right: 20px;
	white-space: nowrap;
	line-height: 1.4;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.03em;
	color: $c-text;
	@include transish();

	&-descr {
		font-weight: 400;
		color: $c-text;
		font-style: italic; } }

.help-block {
	position: absolute;
	bottom: -15px;
	left: 0;
	font-size: 12px;
	line-height: 1;
	color: $red; }

.select-styler {
	// flex: 1 0 auto
 }	// max-width: 350px

.checkbox + .help-block {
	padding-left: 40px;
	bottom: -10px; }

.radio + .help-block {
	padding-left: 0;
	bottom: -10px; }

.attach {
	position: relative;
	padding-left: 24px;
	color: #197A76;
	font-size: 13px;
	font-weight: 700;

	.icon {
		position: absolute;
		width: 19px;
		height: 19px;
		left: 0;
		@include hcenter;
		fill: $c-main; } }
