.industries {
	margin-bottom: var(--marginLg);
	.container {
		@include media($lg) {
			max-width: 100%;
			padding: 0 30px; }
		@include media($sm) {
			padding: 0 15px; } }
	.row {
		@include media($lg) {
			margin: 0;
			justify-content: space-between; } }

	.col-md-4 {
		@include media($lg) {
			flex: initial;
			padding: 0;
			width: calc(33.33% - 20px); }
		@include media($md) {
			width: calc(33.33% - 5px); }
		@include media($sm) {
			width: 100%; } }
	&__item {
		padding: 30px 50px 36px;
		background-color: #FEFEFE;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 40px;
		@include media($lg) {
			transform: none !important;
			opacity: 1 !important;
			padding: 23px 37px 27px;
			border-radius: 30px; }
		@include media($md) {
			padding: 30px; }
		@include media($sm) {
			margin-bottom: 20px;
			padding: 37px 50px 40px; }


		&-pic {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			@include media($lg) {
				margin-bottom: 15px; }
			img {
				max-height: 100%;
				width: 100% !important;
				height: auto !important; } }

		&-title {
			text-align: center;
			font-size: 26px;
			line-height: 1.2;
			font-weight: bold;
			color: #060E41;
			@include media($lg) {
				font-size: 20px; }
			@include media($sm) {
				font-size: 22px; } }


		&-descr {
			margin-top: 20px;
			font-size: 16px;

			@include media($lg) {
				margin-top: 10px; } }

		//&--i1
		//
		//	.decor-lines__item
		//		width: 130px
		//		height: 40px
		//		background-size: 100% 100%
		//
		//		&--side-right
		//			right: -40px
		//
		//			&.pos-1
		//				top: 15px
		//
		//&--i2
		//
		//	.decor-lines__item
		//
		//		&--side-right
		//			right: -62%
		//
		//			&.pos-2
 } }		//				top: 47%
