.trand-card {
	$this: &;
	position: relative;
	background-color: #fff;
	border-radius: 10px;
	overflow: hidden;
	width: calc(33.33% - 8px);
	display: inline-flex;
	flex-wrap: wrap;
	align-content: flex-start;
	align-items: flex-start;
	@include media($sm) {
		width: 100%;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0; } }


	&:after {
		@include pseudo;
		width: 100%;
		height: 8px;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $c-main;
		transform: scale(0);
		transition: transform 0.3s;
		transform-origin: bottom center; }


	&:hover {
		text-decoration: none;
		* {
			text-decoration: none; }
		&:after {
			transform: scale(1); }
		.trand-card__pic::before {
			background: rgba(6,14,65,.4); }
		.link-arrow {
			color: #4AAFAF;

			.icon {
				fill: #4AAFAF; } } }

	&:not(:last-child) {

		@include media($lg) {
			margin-right: 0; } }

	&__pic {
		position: relative;
		width: 100%;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			background: rgba(6, 14, 65, 0.3);
			transition: .3s; }
		img {
			width: 100%!important;
			object-fit: cover;
			margin: 0 auto;
			@include media($sm) {
				height: 223px!important; } } }

	&__body {
		padding: 20px 25px 43px;
		width: 100%;
		@include media($lg) {
			padding: 20px 20px 50px; }
		@include media($sm) {
			padding-top: 25px;
			padding-bottom: 60px; } }

	&__title {
		font-size: 22px;
		color: $c-bg;
		font-weight: bold;
		line-height: 120%;
		overflow: hidden;
		text-align: center;
		width: 100%;
		margin-bottom: 10px;

		@include media($lg) {
			font-size: 20px; }
		@include media($sm) {
			font-size: 22px;
			max-width: 275px;
			margin: 0 auto 10px; } }



	&__descr {
		font-size: 16px;
		line-height: 120%;
		text-align: center;
		color: rgba(6, 14, 65, 1);
		max-width: 260px;
		width: 100%;
		margin: 0 auto 29px;
		font-weight: 350;

		@include media($lg) {
			font-size: 15px;
			margin-bottom: 0; }
		@include media($sm) {
			font-size: 18px; } }

	&__action {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		width: calc(100% - 45px);
		margin-top: auto;
		position: absolute;
		left: 25px;
		bottom: 25px;
		@include media($lg) {
			bottom: 15px;
			left: 20px;
			width: calc(100% - 40px); }

		.date {
			font-weight: normal;
			font-size: 14px;
			line-height: 100%;
			color: rgba(6, 14, 65, 0.6);
			@include media($lg) {
				font-size: 11px; }
			@include media($sm) {
				font-size: 14px; } } }

	.simple-ar-icon {
		color: #35C8C7;
		text-transform: uppercase;
		span {
			font-size: 14px;
			font-weight: bold;
			padding: 0;
			@include media($lg) {
				font-size: 11px; }
			@include media($sm) {
				font-size: 13px; }
			&::before {
				content: none; } }
		.icon {
			width: 12px;
			height: 13px;
			margin-right: 0;
			@include media($lg); } } }




