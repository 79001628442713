.catalog-preloader {
	position: absolute;
	display: none;
	@include sizeblock(100%);
	@extend %trbl;
	z-index: 100;

	&:after {
		@include pseudo;
		@include sizeblock(100%);
		backdrop-filter: blur(2px) opacity(0.85); }

	&:before {
		@include pseudo;
		@include sizeblock(40px);
		@include wcenter;
		top: 150px;
		border-radius: 100%;
		border: 5px solid #666;
		border-top-color: $c-main;
		animation-name: rotate;
		animation-duration: 600ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		z-index: 100; } }

@keyframes rotate {
	from {
		transform: rotate(0deg); }

	to {
		transform: rotate(360deg); } }
