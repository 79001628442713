.footer {
	//margin-top: 60px
	.container {
		@include media($lg) {
			max-width: 100%; } }

	.row {
		@include media($lg) {
			justify-content: space-between;
			padding: 0 15px; }
		@include media($sm) {
			padding: 0 15px; } }

	.col-lg-12 {
		@include media($lg) {
			padding: 0; } }


	&__top {
		position: relative;
		padding: 22px 0;
		background-color: $c-bg;
		border-bottom: 1px solid #fff;

		@include media($md) {
			padding: 13px 0; }
		@include media($sm) {
			padding: 40px 0 25px; } }


	&__middle {
		padding: 35px 0 40px;
		background-color: $c-bg;

		@include media($lg) {
			padding-bottom: 35px; }
		@include media($sm) {
			padding: 45px 0 30px; } }


	&__action {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.nav-menu {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			&__list {
				display: flex;
				flex-wrap: wrap;
				@include media($sm) {
					justify-content: space-between;
					padding: 0 20px; } }

			.nav-menu__item {
				padding: 0 15px;
				margin: 0;
				@include media($lg) {
					padding: 0 10px; }
				@include media($sm) {
					padding: 0;
					width: 45%;
					margin-bottom: 20px; }

				&.active {
					.nav-menu__link {
						color: rgba(255, 255, 255, 1); } } }
			.nav-menu__link {
				margin: 0;
				color: rgba(255, 255, 255, 0.6);
				font-size: 16px;
				font-weight: normal;
				@include media($sm) {
					font-size: 20px;
					line-height: 1.2; }
				&:hover {
					color: rgba(255, 255, 255, 1);
					text-decoration: none; } } } }


	&__meta {
		display: flex;
		justify-content: space-between;
		padding-right: 130px;
		@include media($lg) {
			padding-right: 30px; }
		@include media($sm) {
			flex-wrap: wrap;
			padding-right: 0;
			width: 100%; }
		&-img {
			width: 39%;
			display: flex;
			flex-wrap: wrap;
			@include media($lg) {
				width: 26%; }
			@include media($sm) {
				width: 100%;
				justify-content: space-between;
				align-items: center;


				order: 2; }
			.social {
				width: 100%;
				margin-top: auto; } }

		&-content {}


		.footer__contacts {
			display: flex;
			justify-content: flex-end;
			@include media($sm) {
				width: 100%; } }

		.footer__contacts-row {

			display: grid;

			align-content: flex-start;
			max-width: 200px;
			@include media($sm) {
				flex-wrap: wrap;
				width: 100%;
				margin-bottom: 24px;
				max-width: 100%;
				align-items: flex-end; }

			&:first-child {
				@include media($sm) {
					display: flex;
					flex-wrap: wrap;
					align-content: center;
					align-items: center;
					.footer__contacts-item, .footer__contacts-row__mb {
						width: 50%;
						padding-right: 20px; }
					.footer__contacts-row__mb {
						.footer__contacts-item {
							width: 100%;
							padding-right: 0; } } } }

			&:last-child {
				margin-left: 50px;
				@include media($sm) {
					margin-left: 0;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					margin-bottom: 40px;
					.footer__contacts-item {
						width: auto; } } }

			.footer__contacts-item__zag {
				margin-bottom: 15px;
				font-weight: bold;
				font-size: 18px;
				line-height: 100%;
				color: #FEFEFE;
				width: 100%;
				@include media($lg) {
					margin-bottom: 17px; }
				@include media($sm) {
					font-size: 16px;
					margin-bottom: 20px; } }

			&__mb {
				@include media($sm) {
					width: 50%;
					.footer__contacts-item {
						width: 100%;
						padding: 0 !important;
						&:last-child {
							margin-top: 6px;
							margin-bottom: 0; } } } } }
		.footer__contacts-item {
			width: 100%;
			max-width: 100%;
			padding: 0;
			&:not(:last-child) {
				margin-bottom: 10px; }




			.link-icon__link {
				font-size: 14px;
				text-decoration: none !important; } } }


	&__logo {
		max-width: 160px;
		position: relative;
		&:hover {
			> img {
				opacity: 0; }
			.logo-hover {
				opacity: 1; } }
		.logo-hover {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			transition: .3s;
			img {
				width: 100% !important;
				height: auto!important; } }
		@include media($lg) {
			max-width: 106px; }
		img {
			height: auto !important;
			transition: .3s; } }


	&__bottom {
		padding: 15px 15px; }

	&__copyright {
		text-align: center;
		color: rgba(6, 14, 65, 0.4);
		font-size: 12px;
		font-weight: normal;
		line-height: 100%; }

	&__contacts {
		display: inline-flex;
		flex-wrap: wrap;
		.link-icon__icon {
			@include media($sm) {
				top: 0; } }


		&-row {}

		&-item {
			padding: 0 10px;
			@include media($md) {
				margin-bottom: 10px; } } }

	.social {

		@include media($md) {
			margin-top: 5px; }
		@include media($sm) {
			width: auto;
			margin-top: 0; }

		&__item {

			.icon {
				fill: #fff; }

			&:hover {
				.icon {
					fill: $c-main; } } } }

	.addr {

		&__title {
			position: relative;
			padding-left: 25px;
			font-size: 14px;
			font-weight: bold;
			color: #fff;
			.icon {
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				fill: #fff; } }

		&__text {
			margin-top: 8px;
			padding-left: 25px;
			font-weight: 400;
			font-size: 12px;
			opacity: 0.7;
			color: #fff;
			@include media($sm) {
				margin-top: 10px; } } }

	.link-icon {

		&__icon {

			.icon {
				width: 20px;
				height: 20px;
				fill: #fff; } }
		a.link-icon__link {
			padding-left: 25px;
			color: #fff;
			opacity: 0.7; } } }
