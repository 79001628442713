.email {
	margin-bottom: var(--marginMd);

	&__body {
		position: relative;
		border-radius: 10px;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		padding: 50px 126px 60px;
		display: flex;
		flex-wrap: wrap;
		gap: 43px 45px;
		@include media($lg) {
			gap: 35px;
			padding: 35px 45px 40px; }
		@include media($sm) {
			padding: 40px 40px 53px;
			gap: 27px 0; } }

	&__item {
		text-align: center;
		max-width: 252px;
		@include media($lg) {
			max-width: 225px; }
		@include media($sm) {
			max-width: 100%;
			width: 100%; }
		&-title {
			font-weight: bold;
			color: $c-bg;
			font-size: 18px;
			margin-bottom: 15px;
			line-height: 100%;
			@include media($lg) {
				font-size: 16px; } }

		&-link {
			color: rgba(6, 14, 65, 1);
			text-decoration: underline;
			font-weight: 350;
			line-height: 1;
			@include media($lg) {
				font-size: 14px; }
			&:hover {
				text-decoration: none; } } } }
