.decor-lines {
	$this: &;

	&__item {
		position: absolute;
		width: 256px;
		height: 62px;
		background: url('../images/decor-line.svg') no-repeat 50% 50%;
		background-size: cover;

		@include media($lg) {
			width: 180px;
			height: 45px; }

		@include media($md) {
			width: 128px;
			height: 31px; }

		&.mirror {
			transform: scale(1, -1); }

		&--side-top {
			top: -22px;

			&.pos-1 {
				left: -17%; }

			&.pos-2 {
				left: 10%; }

			&.pos-3 {
				left: 37%; }

			&.pos-4 {
				left: 64%; }

			&.pos-5 {
				left: 91%; } }

		&--side-bot {
			bottom: -22px;

			&.pos-1 {
				left: -17%; }

			&.pos-2 {
				left: 10%; }

			&.pos-3 {
				left: 37%; }

			&.pos-4 {
				left: 64%; }

			&.pos-5 {
				left: 91%; } }

		&--side-left {
			left: -20%;

			&.pos-1 {
				top: 15%; }

			&.pos-2 {
				top: 40%; }

			&.pos-3 {
				top: 65%; } }

		&--side-right {
			right: -20%;

			&.pos-1 {
				top: 15%; }

			&.pos-2 {
				top: 40%; }

			&.pos-3 {
				top: 65%; } } } }
