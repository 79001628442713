.request {
	margin-bottom: var(--marginLg);

	.container {
		@include media($lg) {
			max-width: 100%;
			padding: 0; } }
	.row {
		@include media($lg) {
			justify-content: space-between;
			padding: 0 30px;
			margin: 0; }
		@include media($sm) {
			padding: 0 15px; } }
	.col-12 {
		@include media($lg) {
			padding: 0; } }
	&__inner {
		padding: 50px 25px 55px;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 20px;
		@include media($lg) {
			padding: 45px 15px 50px; }
		@include media($sm) {
			padding: 50px 34px 60px; } }

	&__title {
		text-align: center;
		font-size: 46px;
		font-weight: 800;
		color: $c-bg;
		margin-bottom: 20px;
		line-height: 1.2;
		h2 {
			color:  inherit;
			font-size: inherit;
			font-weight: inherit;
			line-height: inherit; }

		@include media($lg) {
			font-size: 36px; }
		@include media($sm) {
			font-size: 30px;
			margin-bottom: 15px; } }

	&__descr {
		max-width: 560px;
		text-align: center;
		font-size: 18px;
		font-weight: 350;
		color: rgba(6, 14, 65, 1);
		margin: 0 auto 25px;
		line-height: 145%;
		@include media($sm) {
			font-size: 14px;
			margin-bottom: 30px; } }


	&__action {
		text-align: center;
		.btn {
			@include media($sm) {
				width: 100%;
				max-width: 250px; } } }


	&._withText {
		margin-bottom: 50px;
		@include media($lg) {
			margin-bottom: 40px; }


		.request__text {
			padding-top: 50px;
			width: 100%;
			text-align: center;
			font-size: 11px;
			color: rgba(6, 14, 65, 0.3);
			font-weight: normal;
			@include media($lg) {
				padding-top: 40px; }
			a {
				text-decoration: underline;
				color: rgba(6, 14, 65, 0.3);
				font-weight: normal;
				&:hover {
					text-decoration: none; } } } }
	&._zag-hide {
		.request__title {
			display: none; } }
	&._large-text {
		.request__descr {
			max-width: 900px;
			@include media($lg) {
				padding: 0 30px; }
			@include media($sm) {
				text-align: left;
				padding: 0; } } } }

.request__text {

	&._independ {
		width: 100%;
		padding-top: 70px;
		color: rgba(6, 14, 65, 0.3);
		text-align: center;
		font-size: 11px;
		@include media($sm) {
			padding-top: 55px;
			font-size: 9px; }
		* {
			font-weight: normal;
			@include media($sm) {
				font-size: 9px; } }
		a {
			color: rgba(6, 14, 65, 0.3);
			text-decoration: underline;
			&:hover {
				text-decoration: none; } } } }

