.subblock {
	$this: &;
	margin-bottom: 30px;

	&__body {
		position: relative;
		display: flex;
		min-height: 355px;
		padding: 30px 40px 15px;
		background-color: #fff;
		transform: translate(0, 0px);
		transition: box-shadow 0.3s ease, transform 0.3s ease;

		@include media($md) {
			display: block;
			padding: 20px 40px; }

		&:hover {
			transform: translate(0, -2px);
			box-shadow: 0 14px 84px #c2c3cc;

			.link-arrow {
				color: $c-main;

				.icon {
					fill: $c-main; } } }

		&--left-pic {

			#{$this}__pic {}

			#{$this}__content {
				margin-left: 15px;

				@include media($md) {
					margin-left: 0; } }

			#{$this}__action {
				justify-content: flex-end;

				@include media($md) {
					justify-content: flex-start; } } }


		&--right-pic {

			#{$this}__pic {
				order: 1; }

			#{$this}__content {
				margin-right: 15px; }

			#{$this}__action {
				margin-top: 40px;

				@include media($md) {
					margin-top: 15px; } } }

		&--bg-blue {
			background-color: #EEFEFF; } }

	&__pic {
		display: flex;
		align-items: center;
		flex: 1 0 230px;
		max-width: 230px;

		@include media($md) {
			margin: 0 auto; } }

	&__content {

		@include media($md) {
			margin-top: 20px; } }

	&__title {
		display: inline-block;
		margin-bottom: 15px;
		text-align: center;
		font-size: 26px;
		font-weight: 700;
		color: $c-bg; }

	&__texts {
		margin-bottom: 24px; }

	&__text {

		&:not(:last-child) {
			margin-bottom: 16px; } }

	&__props {

		&-item {
			color: $c-bg;

			&:not(:last-child) {
				margin-bottom: 16px; }

			&-title {
				font-weight: 700; }

			&-text {} } }

	&__action {
		display: flex;
		margin-top: 15px; }

	.link-arrow {
		padding-right: 25px;
		font-size: 12px;
		color: #afafaf;

		@include media($lg) {
			color: $c-main; }

		.icon {
			width: 20px;
			height: 20px;
			top: -6px;

			@include media($lg) {
				fill: $c-main; } } } }
