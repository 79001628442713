@mixin hidden-up($breakpoint) {
	@media screen and (min-width: $breakpoint) {
		display: none !important;
	}
}

@mixin hidden-down($breakpoint) {
	@media screen and (max-width: $breakpoint) {
		display: none !important;
	}
}

@mixin media($breakpoint) {
	@media screen and (max-width: $breakpoint - 1) {
		@content;
	}
}

@mixin mediaMin($breakpoint) {
	@media screen and (min-width: $breakpoint) {
		@content;
	}
}

// @mixin below($width, $orientation: 'width') {
// 	@media screen and (max-#{$orientation}: $width) {
// 		@content;
// 	}
// }

// @mixin above($width, $orientation: 'width') {
// 	@media screen and (min-#{$orientation}: $width) {
// 		@content;
// 	}
// }

// @mixin between($minWidth, $maxWidth, $orientation: 'width') {
// 	@media screen and (min-#{$orientation}: $minWidth) and (max-#{$orientation}: $maxWidth) {
// 		@content;
// 	}
// }
