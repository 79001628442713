.doc-card {
	display: flex;
	margin-bottom: 16px;

	&__icon {
		width: 33px;
		flex-shrink: 0;
		margin-right: 13px; }

	&__descr {
		position: relative;
		margin-top: 8px; }

	&__title {
		display: block;
		max-height: 57px;
		font-size: 13px;
		text-decoration: none;
		@include transish;
		color: $c-main;
		overflow: hidden;

		&:before {
			@include pseudo;
			width: 100%;
			height: 100%;
			top: 0;
			left: -46px; }

		&:hover {
			color: $c-main;
			text-decoration: underline;
			max-height: 200px; } }

	&__size {
		font-size: 13px;
		color: #646464;
		line-height: 1; } }
