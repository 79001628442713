.headquarters {
	margin-bottom: 90px;

	&-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 19px;
		@include media($lg) {
			gap: 12px; }
		@include media($sm) {
			gap: 25px 0; }
		&__item {
			width: calc(33.33% - (19px / 3 * 2));
			border-radius: 10px;
			box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
			background: #FFFFFF;
			padding: 40px 39px 50px;
			text-align: center;
			color: rgba(6, 14, 65, 1);
			line-height: 100%;
			@include media($lg) {
				padding: 40px 28px 50px;
				width: calc(33.33% - (12px / 3 * 2)); }
			@include media($sm) {
				padding: 40px 60px 50px;
				width: 100%; }
			.name {

				margin-bottom: 15px;
				font-weight: bold;
				font-size: 24px;
				color: #060E41;
				line-height: 100%; }
			.address {
				font-weight: 350;
				span {
					display: block;
					margin-bottom: 10px;
					@include media($lg) {
						font-size: 12px; }
					@include media($sm) {
						font-size: 14px; } } }
			ul {
				li {
					line-height: 1;
					&:not(:last-child) {
						margin-bottom: 10px; }
					@include media($lg) {
						font-size: 12px; }
					@include media($sm) {
						font-size: 14px; }
					b {
						font-weight: bold;
						color: rgba(6, 14, 65, 0.8);
						line-height: 1;
						display: inline-block; }
					a {
						padding-left: 5px;
						font-weight: 350;
						line-height: 1;
						display: inline-block; } } } } } }



.headquarters {
	&-blocks {
		display: flex;
		justify-content: space-between;
		@include media($sm) {
			flex-wrap: wrap; } }


	&-block {
		width: calc(50% - 10px);
		display: flex;
		flex-wrap: wrap;
		@include media($lg) {
			width: auto;
			&:first-child {
				margin-right: 20px;
				width: 53%;
				@include media($sm) {
					width: 100%;
					margin-right: 0;
					margin-bottom: 67px; } }
			&:last-child {
				width: calc(47% - 20px);
				@include media($sm) {
					width: 100%; } } }

		.section-title {
			width: 100%; }
		&__item {
			background: #FFFFFF;
			box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
			border-radius: 10px;
			padding: 40px 100px 50px;
			width: 100%;
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			@include media($lg) {
				padding: 30px 30px 40px; }

			.zag {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				color: #060E41;
				font-weight: bold;
				font-size: 30px;
				line-height: 100%;
				width: 100%;
				margin-bottom: 25px;
				@include media($lg) {
					font-size: 24px; }

				&:not(:last-child) {
					margin-bottom: 25px;
					@include media($lg) {
						margin-bottom: 20px; } }
				.icon {
					width: 25px;
					height: 25px;
					margin-right: 10px;
					margin-top: -4px;
					fill: #060E41; }
				span {} }
			ul {
				@include media($lg) {
					max-width: 311px;
					margin: 0 auto; }
				li {
					display: flex;
					flex-wrap: wrap;
					font-size: 16px;
					&:not(:last-child) {
						margin-bottom: 15px; }
					span {
						@include media($sm) {
							font-size: 14px; }
						&:nth-child(1) {
							color: rgba(6, 14, 65, 0.8);
							font-weight: bold;
							width: 97px;
							b {
								color: rgba(6, 14, 65, 0.8);
								font-weight: bold; } }
						&:nth-child(2) {
							width: calc(100% - 97px);
							* {
								color: rgba(6, 14, 65, 1);
								font-weight: 350;
								text-decoration: none; }
							a {
								&:hover {
									text-decoration: underline; } } } } } }
			&._simple {
				align-content: center;
				@include media($lg) {
					padding: 45px; }
				.zag {
					margin-bottom: 0;
					justify-content: flex-start;
					max-width: 240px;
					margin-right: auto;
					margin-left: auto;
					@include media($lg) {
						max-width: 200px; }
					&:not(:last-child) {
						margin-bottom: 20px;
						@include media($lg) {
							margin-bottom: 24px; } } } } } } }

