.partnership {
	margin-bottom: var(--marginLg);
	&-list {}
	&-item {
		background: #FFFFFF;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 10px;
		text-align: center;
		padding: 50px 70px 70px;
		@include media($lg) {
			padding: 50px 45px 60px; }
		@include media($sm) {
			padding: 60px 32px 60px; }
		&:not(:last-child) {
			margin-bottom: 70px;
			@include media($lg) {
				margin-bottom: 47px; } }
		&__logo {
			margin: 0 auto 35px;
			text-align: center;
			@include media($sm) {
				margin-bottom: 30px; }
			img {
				margin: 0 auto;
				width: auto!important;
				height: auto!important;
				max-width: 300px;
				max-height: 170px;
				@include media($sm) {
					max-width: 200px; } } }
		&__content {
			p {
				font-weight: 350;
				line-height: 145%;
				color: rgba(6, 14, 65, 1);
				text-align: left;
				@include media($lg) {
					font-size: 14px; }
				&._center {
					text-align: center;
					@include media($sm) {
						text-align: left; }
					&:not(:last-child) {
						margin-bottom: 30px;
						@include media($lg) {
							margin-bottom: 25px; }
						@include media($sm) {
							margin-bottom: 30px; } } }
				&:not(:last-child) {
					margin-bottom: 15px; } }
			.underline {
				text-decoration: underline; }
			b {
				font-weight: 700; }
			a {
				text-decoration: underline;
				&:hover {
					text-decoration: none; } }
			h3 {
				color: #060E41;
				font-size: 18px;
				margin-bottom: 25px;
				font-weight: bold;
				line-height: 1;
				@include media($lg) {
					font-size: 16px;
					margin-bottom: 20px; }
				@include media($sm) {
					line-height: 145%; } }
			.list-style {
				max-width: 535px;
				margin: 0 auto 25px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				@include media($sm) {
					margin-bottom: 30px; }


				li {
					margin-bottom: 15px;
					margin-right: 15px;
					width: auto;
					@include media($lg) {
						font-size: 14px;
						margin-right: 20px; }
					@include media($sm) {
						max-width: 200px;
						width: 100%;
						text-align: left;
						margin-right: 0;
						margin-bottom: 5px; } } } }

		&__adv {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
			margin-bottom: 45px;
			padding: 0 65px;
			@include media($lg) {
				gap: 0;
				margin-bottom: 20px;
				padding: 0 85px;
				justify-content: space-between; }
			@include media($sm) {
				padding: 0 18px;
				margin-bottom: 35px; }
			&-item {
				width: calc(33.33% - (20px / 3 * 2));
				padding: 25px 30px 35px;
				border-radius: 16px;
				background-color: #F1F1FB;
				text-align: center;
				@include media($lg) {
					width: calc(50% - 10px);
					margin-bottom: 20px; }
				@include media($sm) {
					width: 100%;
					margin-bottom: 10px; }
				.zag {
					margin-bottom: 10px;
					font-weight: bold;
					color: rgba(6, 14, 65, 1);
					line-height: 1;
					text-align: center; }
				p {
					font-size: 14px;
					font-weight: 350;
					line-height: 145%;
					text-align: center; } } } } }





