.scroll-top {
	position: fixed;
	display: none;
	bottom: 60px;
	right: 40px;
	@include sizeblock(61px);
	@include circle;
	background: linear-gradient(90deg, #197874 0%, #3CDADA 113.24%), $c-main;
	cursor: pointer;
	@include transish;
	transform: translate(0, 140px);
	z-index: 777;

	@include media($lg) {
		bottom: 20px;
		right: 20px;
		@include sizeblock(42px); }

	&:hover {
		transform: scale(1.1); }

	.icon {
		position: absolute;
		width: 15px;
		height: 19px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) rotate(-90deg);
		fill: #fff;

		@include media($lg) {
			width: 12px;
			height: 15px; } }

	&.active {
		transform: translate(0, 0px); }

	&.nofix {
		position: absolute;
		top: -120px; } }
