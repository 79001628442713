.section-title {
	max-width: 800px;
	margin: 0 auto 50px;
	font-size: 46px;
	font-weight: 800;
	line-height: 135%;
	color: $c-bg;
	text-align: center;
	@include media($lg) {
		font-size: 36px;
		margin-bottom: 30px; }
	@include media($md) {
		font-size: 30px; }

	&._sm {
		margin-bottom: 30px; } }




