.tblock {
	position: relative;
	margin-bottom: var(--marginLg);

	&__title {
		margin-bottom: 30px;
		font-size: 46px;
		font-weight: 800;
		color: $c-bg;
		text-align: center;
		@include media($lg) {
			font-size: 36px; }
		@include media($sm) {
			font-size: 30px; }
		h2 {
			font-size: inherit;
			color: inherit;
			font-weight: inherit; } }



	&__body {
		position: relative;
		padding: 40px 70px 60px;
		background: #FFFFFF;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 10px;
		color: rgba(6, 14, 65, 1);
		@include media($lg) {
			padding: 40px 45px 50px; }
		@include media($sm) {
			padding: 40px 33px 45px; }
		.zag {
			font-weight: bold;
			font-size: 22px;
			line-height: 100%;
			color: #060E41;
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
			@include media($lg) {
				font-size: 22px; }
			h3 {
				font-size: inherit;
				font-weight: inherit;
				color: inherit; } }
		p {
			color: rgba(6, 14, 65, 1);
			font-weight: 350;
			font-size: 16px;
			line-height: 145%;
			@include media($lg) {
				font-size: 14px; }

			b {
				color: rgba(6, 14, 65, 1);
				font-weight: bold; }
			&:not(:last-child) {
				margin-bottom: 15px; } }
		.simple-txt__list {
			margin-top: 25px; }
		.list-style {
			margin-bottom: 25px;
			padding: 0 50px;

			@include media($lg) {
				padding: 0; }
			@include media($sm) {
				flex-wrap: wrap;
				padding: 0;
				max-width: 220px;
				margin: 0 auto 25px; }



			li {
				font-size: 14px;
				@include media($sm) {
					margin-right: 0;
					&:not(:last-child) {
						margin-right: 0;
						margin-bottom: 5px; } } } } }


	&__action {
		margin-top: 30px;
		text-align: center; }

	&__pic {

		img {
			margin: 0 auto; }

		&-descr {
			margin-top: 30px;
			text-align: center; } } }
