.stars-rating {
	position: relative;
	width: 70px;
	height: 12px;
	background-size: 14px 12px;
	background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' fill='%23ebebeb' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.077.72c.341-.821 1.505-.821 1.846 0l.958 2.304a1 1 0 00.844.612l2.487.2c.886.07 1.246 1.177.57 1.756l-1.894 1.623a1 1 0 00-.322.992l.578 2.426c.207.866-.734 1.55-1.494 1.086l-2.129-1.3a1 1 0 00-1.042 0l-2.13 1.3c-.759.463-1.7-.22-1.493-1.085l.578-2.427a1 1 0 00-.322-.992L1.217 5.592c-.675-.579-.316-1.685.571-1.756l2.487-.2a1 1 0 00.844-.612L6.077.72z' /%3E%3C/svg%3E");

	&:after {
		@include pseudo;
		top: 0;
		left: 0;
		width: 0%;
		height: 100%;
		background-size: 14px 12px;
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' fill='%23FDCA33' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.077.72c.341-.821 1.505-.821 1.846 0l.958 2.304a1 1 0 00.844.612l2.487.2c.886.07 1.246 1.177.57 1.756l-1.894 1.623a1 1 0 00-.322.992l.578 2.426c.207.866-.734 1.55-1.494 1.086l-2.129-1.3a1 1 0 00-1.042 0l-2.13 1.3c-.759.463-1.7-.22-1.493-1.085l.578-2.427a1 1 0 00-.322-.992L1.217 5.592c-.675-.579-.316-1.685.571-1.756l2.487-.2a1 1 0 00.844-.612L6.077.72z' /%3E%3C/svg%3E"); }

	&--1 {

		&:after {
			width: 20%; } }

	&--2 {

		&:after {
			width: 40%; } }

	&--3 {

		&:after {
			width: 60%; } }

	&--4 {

		&:after {
			width: 80%; } }

	&--5 {

		&:after {
			width: 100%; } } }
