.jlist {
	margin-bottom: 60px;

	&__body {
		padding: 20px 165px;
		background: #EEFEFF;

		@include media($lg) {
			padding: 20px 40px; } }

	&__item {

		@include media($lg) {
			margin-bottom: 20px; }

		&:not(:last-child) {
			margin-bottom: 30px;

			@include media($lg) {
				margin-bottom: 20px; } }

		&-title {
			font-weight: 700;
			color: $c-bg; }

		&-list {

			ul {}

			li {
				position: relative;
				padding-left: 20px;
				color: $c-bg;

				&:after {
					@include pseudo;
					left: 0;
					top: 6px;
					width: 12px;
					height: 10px;
					border-radius: 30% / 50%;
					background-color: $c-main; } } }

		&-text {
			display: inline-block;
			color: $c-text; } } }
