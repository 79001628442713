.benefits {
	margin-bottom: var(--marginLg);
	.row {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		gap: 18px;
		@include media($lg) {
			gap: 0;
			justify-content: space-between; } }

	&__item {
		display: inline-flex;
		align-items: flex-start;
		align-content: flex-start;
		flex-wrap: wrap;
		flex: 1;
		padding: 40px;
		background: #FFFFFF;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 20px;
		text-decoration: none!important;
		@include media($lg) {
			flex: inherit;
			width: calc(50% - 10px);
			margin-bottom: 20px;
			padding: 40px 59px 70px;
			&:last-child {
				margin-bottom: 0; } }
		@include media($sm) {
			width: 100%;
			padding: 40px 40px 70px; }

		&-pic {
			margin: 0 auto 20px;
			@include media($lg) {
				width: 100%; }
			img {
				width: 100% !important;
				height: auto !important; } }


		&-title {
			margin-bottom: 10px;
			font-size: 16px;
			font-weight: bold;
			color: $c-bg;
			line-height: 1;
			text-align: center;
			width: 100%;
			@include media($lg) {
				font-size: 20px;
				line-height: 145%;
				max-width: 200px;
				margin: 0 auto 10px; }
			@include media($sm); }


		&-list {
			@include media($lg) {
				max-width: 200px;
				margin: 0 auto; }
			ul {
				li {
					position: relative;
					padding-left: 13px;
					font-size: 13px;
					font-weight: 350;
					color: rgba(6, 14, 65, 1);
					line-height: 120%;
					@include media($lg) {
						font-size: 16px;
						line-height: 120%; }
					@include media($sm);


					&:not(:last-child) {
						margin-bottom: 3px; }

					&:after {
						@include pseudo;
						@include sizeblock(7px);
						@include circle;
						left: 0;
						top: 5px;
						background-color: $c-main; } } } } }
	a.benefits__item {
		transition: .3s;
		&:hover {
			box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.25); } }
	&._w33 {
		.row {
			gap: 25px;
			@include media($lg) {
				gap: 15px; }
			@include media($sm) {
				gap: 29px 0; } }
		.benefits__item {
			flex: inherit;
			width: calc(33.33% - (25px / 3 * 2 ));
			padding: 35px 50px 45px;
			justify-content: center;
			margin: 0;
			@include media($lg) {
				padding: 35px 34px 40px;
				width: calc(33.33% - (15px / 3 * 2 )); }
			@include media($sm) {
				padding: 40px 63px 68px;
				width: 100%; }
			&-pic {
				width: 100%;
				max-width: 200px;
				@include media($lg) {
					margin-bottom: 15px; }
				@include media($sm) {
					margin-bottom: 20px;
					max-width: 220px; } }
			&-title {
				font-size: 22px;
				@include media($lg) {
					font-size: 18px; }
				@include media($sm) {
					font-size: 20px; } }
			&-list {

				margin: 0 auto;
				ul {
					li {
						font-size: 16px;
						@include media($lg) {
							font-size: 14px; }
						@include media($sm) {
							font-size: 16px; }
						&:not(:last-child) {
							margin-bottom: 5px; } } } } } }

	&-footer {
		padding: 60px 0 0;
		text-align: center;
		margin: 0 auto;
		@include media($lg) {
			padding-top: 43px; }
		&__img {
			margin-bottom: 60px;
			text-align: center;
			@include media($lg) {
				margin-bottom: 43px; }
			img {
				width: 110px !important;
				height: auto!important;
				margin: 0 auto; } }
		&__link {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			.icon {
				width: 44px;
				height: 40px;
				fill:  #070F42;
				margin: 0 20px 0 0; }
			span {
				font-size: 24px;
				font-weight: bold;
				line-height: 145%;
				color: #060E41;
				@include media($lg) {
					font-size: 22px; }
				@include media($sm) {
					font-size: 20px; } } } } }
