.wecan {
	margin-bottom: var(--marginLg);
	&__body {
		position: relative;
		padding: 50px 60px;
		@include media($lg) {
			padding: 45px 83px 40px; }
		@include media($sm) {
			padding: 30px 30px 25px; }

		.row {
			display: flex;
			flex-wrap: wrap;
			gap: 0 35px;
			@include media($lg) {
				gap: 0 40px; }
			@include media($sm) {
				gap: 0;
				margin: 0; } } }


	&__item {
		position: relative;
		max-width: 280px;
		width: 100%;
		justify-content: center;
		margin: 0 auto 25px;
		padding-top: 25px;
		font-weight: 350;
		font-size: 16px;
		line-height: 120%;
		text-align: center;
		color: rgba(6, 14, 65, 1);

		display: inline-flex;
		@include media($lg) {
			max-width: 284px; }
		@include media($sm) {
			max-width: 100%;
			margin-bottom: 25px; }
		&:before {
			@include pseudo;
			@include wcenter;
			top: 0;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: $c-main; } }

	&-help {
		text-align: center;
		font-weight: 350;
		color: rgba(6, 14, 65, 1);
		margin-top: 40px;
		font-size: 16px;
		padding: 0 15px; } }
