.paginations {
	padding: 60px 0 0;
	@media screen and (max-width: 576px){
		padding: 30px 0 0
	}


	&__list {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__item {
		padding: 0 12.5px;
		user-select: none;
		overflow: hidden;

		&:before {
			content: none;
		}

		&--active {

			.paginations__link {
				color: $c-main;
				cursor: default;
			}
		}

		.icon {
			position: absolute;
			width: 10px;
			height: 10px;
			fill: $c-main;
			@include center;
			@include transish;
		}

		&--first,
		&--last {

			.paginations__link {
				position: relative;
				display: block;
				width: 16px;
				height: 16px;
				padding: 0;
				@include circle;
				background-color: $c-main;

				&:hover {


				}

				.icon {
					fill: #fff;
					width: 8px;
					height: 8px;
				}
			}
		}

		&--first {
			// margin-right: 10px;
		}

		&--last {
			// margin-left: 10px;

			.icon {
				transform: translate(-50%, -50%) rotate(-180deg);
			}
		}
	}

	&__link {
		display: block;
		font-size: 22px;
		text-align: center;
		line-height: 1;
		letter-spacing: 0.1em;
		font-weight: bold;
		@include transish;
		color: rgba(6, 14, 65, 0.5);

		&:hover {
			color: $c-main !important;
			text-decoration: none;
		}
	}

	span.paginations__link {

		&:hover {
			color: $c-text !important;
			background-color: #fff;

		}
	}

	.paginations__item--active.paginations__item--first,
	.paginations__item--active.paginations__item--last {
		visibility: hidden;
	}
}
