.list-inform {
	margin-bottom: var(--marginLg);
	&__item {
		border-radius: 10px;
		background: #EAF9F9;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		padding: 35px 80px 45px;
		display: flex;
		flex-wrap: wrap;
		&._wh {
			background-color: #fff; }
		@include media($lg) {
			padding: 35px 45px 40px; }
		@include media($sm) {
			padding: 40px 27px 50px; }
		&:not(:last-child) {
			margin-bottom: 28px; }
		&-img {
			width: 200px;
			display: flex;
			align-items: center;
			@include media($sm) {
				width: 100%;
				margin-bottom: 20px; }
			img {
				width: auto!important;
				height: auto!important;
				@include media($sm) {
					min-width: 225px;
					margin: 0 auto; } } }
		&-content {
			width: calc(100% - 200px);
			padding-left: 25px;
			@include media($sm) {
				width: 100%;
				padding-left: 0; } }
		&-more {
			margin-top: 20px; }

		h3 {
			color: #060E41;
			font-size: 22px;
			margin-bottom: 10px;
			font-weight: bold;
			line-height: 135%;
			@include media($lg) {
				font-size: 18px;
				margin-bottom: 5px; }
			@include media($sm) {
				text-align: center;
				font-size: 22px;
				margin-bottom: 10px; } }
		.list-style {
			padding-right: 70px;
			@include media($lg) {
				padding-right: 0; }

			li {
				font-weight: 350;
				color: rgba(6, 14, 65, 1);
				font-size: 16px;
				@include media($lg) {
					font-size: 14px; }
				&:not(:last-child) {
					margin-bottom: 5px;
					@include media($sm) {
						margin-bottom: 10px; } } } }
		p {
			font-weight: 350;
			color: rgba(6, 14, 65, 1);
			width: 100%;
			font-size: 16px;
			line-height: 145%;
			@include media($lg) {
				font-size: 14px; }
			&:not(:last-child) {
				margin-bottom: 10px; } } } }
