.department {
	$this: &;
	margin-bottom: var(--marginLg);

	&-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;
		width: 100%;
		padding: 0 15px;
		@include media($lg) {
			justify-content: center;
			flex-wrap: wrap;
			gap: 45px 6px; } }


	&__item {
		position: relative;

		text-align: center;
		background-color: $trp;
		border-radius: 10px;
		padding-bottom: 20px;
		flex: 1;
		@include transish;
		transform: translate(0, 0);
		@include media($lg) {
			flex: inherit;
			width: calc(33.33% - (6px / 3 * 2)); }
		@include media($sm) {
			width: 100%;
			max-width: 240px;
			padding: 0; }
		@media screen and (min-width: 576px) {
			&:hover {
				transform: translate(0, -5px);
				background-color: #fff;
				box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.09);

				#{$this}__item-soclink {
					transform: translate(0, 0);
					opacity: 1; } } }

		&-top {
			position: relative;
			margin: 0 auto;
			@include media($lg) {
 } }				//max-width: 200px


		&-pic {
			width: 100%;
			padding: 20px 25px 0;
			margin-bottom: 20px;
			@include circle;
			overflow: hidden;
			@include media($sm) {
				padding: 0;

				max-width: 200px;
				margin: 0 auto 25px; }
			img {
				margin: 0 auto;
				border-radius: 50%;
				height: auto!important;
				@include media($lg) {
					width: 200px!important; } } }


		&-soclink {
			position: absolute;
			display: block;
			width: 62px;
			height: 34px;
			bottom: 0;
			right: 25px;
			background: #2868B2;
			border-radius: 20px;
			@include transish;
			transform: translate(25px, 0);
			opacity: 0;

			@include media($lg) {
				opacity: 1;
				transform: none; }

			.icon {
				position: absolute;
				@include center;
				width: 15px;
				height: 15px;
				fill: #fff; } }

		&-name {
			font-size: 18px;
			font-weight: bold;
			color: #060E41;
			margin-bottom: 10px;
			line-height: 1;
			display: inline-block;
			@include media($lg) {
				font-size: 22px;
				margin-bottom: 10px; } }

		&-prof {
			font-size: 14px;
			font-weight: 350;
			color: rgba(6, 14, 65, 1);
			line-height: 1;
			@include media($lg) {
				font-size: 18px; } } } }
