.architecture {
	margin-bottom: var(--marginLg);

	.row {}


	&__inner {}


	&__title {
		text-align: center;
		font-size: 30px;
		font-weight: 800;
		line-height: 1;
		color: $c-bg;
		margin-bottom: 40px;
		@include media($lg) {
			font-size: 20px;
			margin-bottom: 30px; }
		@include media($sm);
		h3 {
			font-size: inherit;
			font-weight: inherit;
			color: inherit; } }

	&__pic {
		img {
			width: 100%; } }
	&__body {
		position: relative;
		margin-bottom: 40px;
		@include media($lg) {
			margin-bottom: 30px; }
		@include media($sm) {
			margin-bottom: 25px; } }

	&__footer {

		margin: 0 auto;
		text-align: center;
		@include media($lg) {
			text-align: left; }
		p {
			line-height: 145%;
			font-weight: 350;
			color: rgba(6, 14, 65, 1);
			@include media($sm) {
				display: initial; } } }

	&__links {}

	&__link {
		position: absolute;
		display: block;
		cursor: pointer;
		@include transish;

		&:hover {
			background-color: rgba($c-main, 0.25); }

		&--l1 {
			@include sizeblock(17%, 21%);
			top: 17%;
			left: 0px; }

		&--l2 {
			@include sizeblock(20%, 21%);
			top: 47%;
			left: 0px; }

		&--l3 {
			@include sizeblock(20%, 24%);
			top: 76%;
			left: 0px; }

		&--l4 {
			@include sizeblock(19%, 22%);
			bottom: 3%;
			right: 2%; }

		&--l5 {
			@include sizeblock(10%, 18%);
			bottom: 40%;
			right: 6%; }

		&--l6 {
			@include sizeblock(42%, 26%);
			top: 10%;
			right: 4%; }

		&--l7 {
			@include sizeblock(14%, 7%);
			top: 20%;
			left: 18%; }

		&--l8 {
			@include sizeblock(18%, 14%);
			top: 36%;
			left: 38%; }

		&--l9 {
			@include sizeblock(17%, 14%);
			top: 50%;
			left: 22%; }

		&--l10 {
			@include sizeblock(23%, 12%);
			bottom: 6%;
			left: 21%; }

		&--l11 {
			@include sizeblock(29%, 34%);
			bottom: 0%;
			left: 46%; } } }
