.trands {
	position: relative;
	padding: 65px 0 75px;
	background-color: $c-bg;
	margin-bottom: 70px;
	@include media($lg) {
		padding: 35px 0 40px;
		margin-bottom: 60px; }
	@include media($sm) {
		padding: 50px 0; }
	.container {
		@include media($lg) {
			max-width: 100%; } }
	.row {
		@include media($lg) {
			justify-content: space-between;
			padding: 0 15px; }
		@include media($sm) {
			padding: 0 15px; } }
	.col-12 {
		@include media($lg) {
			padding: 0; } }



	&__bg {
		position: absolute;
		@include sizeblock(100%);
		@extend %trbl;
		z-index: 10;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover; } }

	&__inner {
		position: relative;
		z-index: 20; }

	&__title {
		width: 100%;
		margin-bottom: 50px;
		@include media($lg) {
			margin-bottom: 30px; }

		&-text {
			text-align: center;
			width: 100%;
			font-size: 46px;
			font-weight: 800;
			color: #FDFDFD;
			line-height: 1;
			h2 {
				color:  inherit;
				font-size: inherit;
				font-weight: inherit;
				line-height: inherit; }

			@include media($lg) {
				max-width: initial;
				text-align: center;
				font-size: 36px; }
			@include media($md) {
				font-size: 30px; } } }

	&__action {
		position: relative;
		margin-top: 40px;
		text-align: center;
		z-index: 20; }

	&__cards {
		display: flex;
		justify-content: space-between;
		@include media($lg) {
			margin-top: 30px; }
		@include media($sm) {
			flex-wrap: wrap; } } }
