.label {
	display: inline-block;
	color: $c-main;
	text-transform: uppercase;
	text-align: center;
	font-size: 12px;
	line-height: 1;
	font-weight: 500;
	border-radius: 2px;
	user-select: none;
	letter-spacing: 0.1em;

	&:hover {
		text-decoration: none !important; }

	&--accent {
		padding: 6px 8px;
		background-color: $c-main;
		color: #fff !important;

		&:hover {
			background-color: #b93f3f; }

		&:active {
			background-color: #9e3636; } }

	&--gray {
		padding: 6px 8px;
		background-color: #e5e9f2;

		&:hover {
			background-color: $c-main;
			color: #fff; }

		&:active {
			background-color: $c-main;
			color: #fff; } }

	&--white {
		padding: 6px 0;
		color: #fff !important;

		&:hover {
			color: #f2a0a0; }

		&:active {
			color: #d66060; } }

	&--blue {
		padding: 6px 8px;
		background-color: $c-main;
		color: #fff !important;

		&:hover {
			background-color: $c-main; }

		&:active {
			background-color: $c-main; } }

	&--sky {
		padding: 6px 0;
		color: $c-main;

		&:hover {
			color: $c-main; }

		&:active {
			color: $c-main; } } }
