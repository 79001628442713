// Grid
$lg: 1150px;
$md: 768px;
$sm: 576px;

$gutter: 30px;

:root{
	--marginSm: 50px;
	--marginMd: 80px;
	--marginLg: 120px;
	@media screen and (max-width: $lg){
		--marginLg: 90px;
		--marginMd: 70px;
	}
	@media screen and (max-width: $sm){
		--marginSm: 80px;
		--marginMd: 60px;
	}
}


$grid-breakpoints: (
	xs: 0,
	sm: $sm,
	md: $md,
	lg: $lg,
);

$container-max-widths: (
	sm: 575px,
	md: 768px,
	lg: 1080px,
);

// Fonts types
$c-size: 16px;
$c-lh: 1.4;
$font-1: "Circe", Arial, Verdana, sans-serif;
//$font-2: "Montserrat", Arial, Verdana, sans-serif;
$font-2: "Circe", Arial, Verdana, sans-serif;

// Colors
$c-main:		#35C8C7;
$c-sec:			#36186E;

$c-bg:			#060E41;
$c-text:		rgba(7, 15, 66, 0.5);

$trp: 			transparent;

$blue: #234399;
$red: #F45057;
$green: #379F2F;
$yellow: #e8a12b;
$white: #fff;
$black: #000;
$gray: #d9d9d9;

