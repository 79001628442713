.choose {
	margin-bottom: var(--marginMd);
	.container {
		@include media($lg) {
			max-width: 100%; } }

	.col-md-6 {
		@include media($lg) {
			width: calc(25% - 17.5px);
			flex: initial;
			padding: 0; }
		@include media($sm) {
			width: 100%;
			margin-bottom: 60px;
			&:last-child {
				margin-bottom: 0; } } }
	.row {
		@include media($lg) {
			justify-content: space-between;
			padding: 0 30px; }
		@include media($sm) {
			padding: 0 15px; } }


	&__item {
		text-align: center;
		margin-bottom: 20px;
		@include media($lg) {
			margin-bottom: 0; }

		&-pic {
			margin: 0 auto 20px;
			padding: 0 20px;
			@include media($lg) {
				margin-bottom: 15px; }
			@include media($sm) {
				padding: 0 35px;
				margin-bottom: 20px; }
			img {
				width: 100% !important;
				height: auto !important; } }

		&-title {
			color: $c-bg;
			font-size: 22px;
			font-weight: bold;
			line-height: 100%;
			margin-bottom: 5px;
			@include media($lg) {
				font-size: 18px; }
			@include media($sm) {
				font-size: 22px;
				margin-bottom: 10px; } }

		&-descr {
			font-size: 16px;
			font-weight: 350;
			color: rgba(6, 14, 65, 1);
			@include media($lg) {
				font-size: 12px;
				max-width: 170px;
				margin: 0 auto; }
			@include media($sm) {
				font-size: 18px;
				max-width: 100%; } } }

	&._bg {
		.row {
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			@include media($lg) {
				gap: 0;
				padding: 0; } }
		.choose__item {
			background: #F1F1FB;
			border-radius: 6px;
			padding: 30px 25px 40px;
			flex: 1;
			@include media($lg) {
				flex: inherit;
				width: calc(50% - 10px);
				margin-bottom: 20px;
				padding: 35px 52px 45px; }
			@include media($sm) {
				width: 100%;
				padding: 35px 36px 40px; }
			.choose__item-pic {
				padding: 0 15px;
				margin-bottom: 15px;
				@include media($lg) {
					margin-bottom: 20px;
					padding: 0; } }
			&-title {
				margin-bottom: 5px;
				font-size: 18px;
				@include media($lg) {
					font-size: 22px; } }
			&-descr {
				font-weight: 350;
				font-size: 14px;
				color: rgba(6, 14, 65, 1);
				@include media($lg) {
					max-width: 270px;
					margin: 0 auto;
					font-size: 18px; } } } } }


