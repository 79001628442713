@import '../../assets/css/common/components-import';

.btn-toggle-target {
	display: none;
	padding: 20px;
	background-color: #00ffa6;

	&.active {
		display: block;
	}
}

.btn-toggle-target-fixed {
	display: block !important;
	position: fixed;
	top: 0;
	left: 0;
	width: 200px;
	height: 100%;
	transform: translateX(-100%);
	z-index: 1050;
	transition: transform .3s;
	pointer-events: none;

	&.active {
		transform: translateX(0);
		pointer-events: auto;
	}
}

.btn-toggle-target-fixed__wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1040;
	pointer-events: none;

	.btn-toggle-target-fixed__overflow {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, .8);
		z-index: 1041;
		display: none;
	}

	.btn-toggle-target-fixed {
		z-index: 1042;
	}

	&.active {
		pointer-events: auto;
		.btn-toggle-target-fixed__overflow {
			display: block;
		}

		.btn-toggle-target-fixed {
			transform: translateX(0);
			pointer-events: auto;
		}
	}
}
.ofh {overflow: hidden!important;}
