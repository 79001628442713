.page-title {
	position: relative;
	z-index: 50;
	width: 100%;
	background-size: cover;
	background-position: center;
	text-align: center;
	margin-bottom: var(--marginMd);
	&._sm {
		.page-title__wrp {
			padding: 87px 0;
			min-height: 440px;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			@include media($lg) {
				padding: 97px 0;
				min-height: 300px; }
			@include media($sm) {
				padding: 103px 0; } }
		.page-title__text {
			max-width: 990px;
			margin-bottom: 0;
			@include media($lg) {
				max-width: 510px; } }
		.page-title__descr {
			margin-top: 20px;
			margin-bottom: 0; }
		.page-title__action {
			margin-top: 50px;
			@include media($lg) {
				margin-top: 30px; }
			@include media($sm) {
				margin-top: 40px; } } }

	&._lg {
		.page-title__wrp {
			min-height: 670px;
			display: flex;
			flex-wrap: wrap;
			align-content: center;
			align-items: center;
			@include media($lg) {
				min-height: 450px; } }
		.date {
			text-align: center;
			font-size: 16px;
			font-weight: 350;
			margin-bottom: 20px;
			color: rgba(255, 255, 255, 1);
			display: inline-block;
			@include media($lg) {
				font-size: 14px; } }
		.page-title__text {
			max-width: 100%;
			font-size: 80px;
			@include media($lg) {
				font-size: 45px;
				max-width: 574px; }
			@include media($sm) {
				font-size: 35px; } }
		.page-title__descr {
			font-size: 18px;
			margin-bottom: 0;
			@include media($lg) {
				font-size: 16px; } } }

	&__wrp {
		width: 100%;
		padding: 85px 0;
		position: relative;
		@include media($lg) {
			padding: 80px 0 100px; }
		@include media($sm) {
			padding: 85px 0; } }
	.container {
		position: relative;
		z-index: 2; }
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(6, 14, 65, 0.76) 0%, rgba(6, 14, 65, 0.75) 50.52%, rgba(6, 14, 65, 0.84) 100%);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1; }
	.breadcrumb {
		position: absolute;
		top: 0;
		left: 0;
		@include media($lg) {
			left: 15px; }
		@include media($sm) {
			left: 15px; }
		.breadcrumb__link, .breadcrumb__link:before {
			color: #FFFFFF;
			font-weight: 600; }
		.breadcrumb__item:last-child .breadcrumb__link {
			color: rgba(255, 255, 255, 0.65) !important; } }
	&__text {
		max-width: 860px;
		margin: 0 auto 20px;
		font-size: 90px;
		line-height: 120%;
		font-weight: 800;
		color: #FBFBFB;
		text-align: center;

		@include media($lg) {
			font-size: 45px;
			max-width: 413px;
			margin-bottom: 10px; }
		@include media($sm) {
			font-size: 30px;
			margin-bottom: 15px;
			max-width: 290px; } }

	&__descr {
		max-width: 680px;
		font-size: 20px;
		text-align: center;
		font-weight: 350;
		line-height: 145%;
		color: #FBFBFB;
		margin: 0 auto 50px;
		@include media($lg) {
			margin-bottom: 30px;
			max-width: 555px;
			font-size: 16px; }
		@include media($sm) {
			margin-bottom: 40px; } }


	&__action {
		text-align: center;
		.btn {
			@include media($sm) {
				width: 100%;
				max-width: 250px; } } } }
