.datapicker {

	&:hover {

		.datapicker__icon {
			background-color: $c-main;

			.icon {
				fill: #fff; } } }

	&__field {
		position: relative;
		z-index: 10; }

	&__icon {
		position: absolute;
		@include sizeblock(40px);
		top: 1px;
		right: 1px;
		@include hcenter;
		z-index: 10;
		cursor: pointer;
		@include transish;

		&:hover {
			background-color: $c-main;

			.icon {
				fill: #fff; } }

		.icon {
			position: absolute;
			@include center;
			@include sizeblock(18px);
			fill: $c-sec;
			@include transish; } } }

.form-group {

	&:hover {

		.datapicker {

			.form-control {
				border-color: #E7E7E7;
				cursor: pointer; } } } }
