.simple-txt {
	margin-bottom: var(--marginLg);
	&__wrp {
		p {
			color: rgba(6, 14, 65, 1);
			line-height: 145%;
			text-align: center;
			font-weight: 350;
			margin-bottom: 15px;
			@include media($lg) {
				font-size: 14px;
				margin-bottom: 20px; }
			@include media($sm) {
				font-size: 16px;
				margin-bottom: 25px;
				text-align: left; } } }

	&__list {
		margin-top: 35px;
		&-zag {
			text-align: center;
			font-weight: bold;
			margin-bottom: 20px;
			font-size: 22px;
			line-height: 145%;
			color: $c-bg;
			@include media($lg) {
				font-size: 18px; }
			h3 {
				font-size: inherit;
				font-weight: inherit;
				color: inherit; } }

		ul {
			display: flex;
			justify-content: center;
			width: 100%;
			li {
				&:not(:last-child) {
					margin-right: 20px; }
				@include media($lg) {
					font-size: 16px; }
				@include media($sm) {
					&:not(:last-child) {
						margin-right: 15px; } } } } } }

