.solutions {
	margin-bottom: var(--marginLg);

	&__body {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		gap: 20px 20px;
		justify-content: center;
		padding: 25px 15px 0;
		@include media($lg) {
			gap: 0;
			padding: 0;
			margin-left: -9px;
			margin-right: -9px; }
		@include media($sm) {
			margin-left: -10px;
			margin-right: -10px;
			padding: 0 23px; }
		&._w25 {
			max-width: 640px;
			margin: 0 auto;
			gap: 45px 43px;
			@include media($lg) {
				gap: 40px 43px; }
			@include media($sm) {
				gap: 30px 25px; }
			.solutions__item {
				box-shadow: none;
				max-width: 120px;
				width: 100%;
				padding: 0;
				@include media($lg) {
					margin: 0;
					max-width: 98px; } } } }


	&__item {
		display: inline-flex;
		justify-content: center;
		flex-wrap: wrap;
		width: calc(100% / 6);
		padding: 28px;
		background: #FEFEFE;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 20px;
		text-decoration: none !important;
		transition: .3s;
		@include media($lg) {
			width: calc(20% - 18px);
			padding: 17px 21px 18px;
			margin: 0 9px 18px; }
		@include media($sm) {
			width: calc(50% - 20px);
			margin: 0 10px 20px; }


		&:hover {
			box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.25); }

		&-pic {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			text-align: center;
			@include transish;
			margin-bottom: 15px;
			@include media($lg) {
				margin-bottom: 7px; }
			img {
				width: auto !important;
				height: 70px !important;
				@include media($lg) {
					height: 50px !important; } } }

		&-title {
			display: block;
			text-align: center;
			font-size: 16px;
			line-height: 130%;
			font-weight: normal;
			color: $c-bg;
			@include media($lg) {
				font-size: 14px; } } } }

