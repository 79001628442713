.article-title {
	$this: &;
	position: relative;
	display: flex;
	align-items: center;
	min-height: 384px;
	margin-top: -64px;
	margin-bottom: 45px;
	z-index: 50;

	&__title {
		max-width: 900px;
		margin: 0 auto;
		padding: 0 40px;
		text-align: center;
		font-size: 76px;
		font-weight: 800;
		line-height: 1;

		@include media($lg) {
			font-size: 50px; }

		@include media($md) {
			font-size: 36px; } }

	&__bg {
		position: absolute;
		@extend %trbl;
		width: 100%;
		height: 100%;
		z-index: -10;

		.bgpic {
			width: 100%;
			height: 100%;
			object-fit: cover; } }

	&--white {

		#{$this}__title {
			color: #fff; } }

	&--dark {

		#{$this}__title {
			color: $c-bg; } } }
