@import './swiper';

.swiper-slider {
	position: relative;
}

.swiper-container {
	width: 100%;
}

.swiper-button {
	background-color: $trp;
	transition: background-color 0.3s ease-in-out, opacity 0.3s ease;

	body.no-js & {
		display: none
	}

	&:focus {
		outline: none;
	}

	&:hover {

		.icon {
			fill: $c-main;
		}
	}

	.icon {
		position: absolute;
		left: 50%;
		top: 50%;
		@include sizeblock(20px, 20px);
		@include transish;
		fill: $c-sec;
	}

	&-prev {

		.icon {
			transform: translate(-50%, -50%)
		}
	}

	&-next {

		.icon {
			transform: translate(-50%, -50%) rotate(180deg)
		}
	}
}

.swiper-pagination {
	display: flex;
	bottom: -45px;

	body.no-js & {
		display: none
	}

	&-bullet {
		position: relative;
		@include sizeblock(12px);
		margin: 0 5px;
		transform: scale(1);
		background-color: #F79846;
		opacity: 1;
		@include transish;
		will-change: width, margin, opacity;
		border-radius: 6px;



		// &:after {
		// 	@include pseudo;
		// 	@include sizeblock(100%, 1px);
		// 	top: 0;
		// 	@include wcenter;
		// 	background-color: #fff;
		// }

		&:focus {
			outline: none;
		}

		&-active {
			width: 60px !important;
			opacity: 1 !important;
			visibility: visible !important;
			margin: 0 5px !important;
			transform: scale(1);
		}

		&.hidden-dot {
			width: 0;
			opacity: 0;
			visibility: hidden;
			margin: 0;
		}
	}

	&.not-init {
		opacity: 0;
		visibility: hidden;
	}
}
