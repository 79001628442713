.alert {
	$this: &;
	display: flex;
	margin-bottom: 30px;

	&__icon {
		position: relative;
		flex: 1 0 54px;
		max-width: 54px;
		min-height: 54px;

		.icon {
			position: absolute;
			@include center;
			@include sizeblock(32px);
			fill: #fff; } }

	&__body {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		padding: 15px 20px;

		@include media($md) {
			padding: 15px; } }

	&__title {
		margin-right: 15px;
		font-weight: 700;
		font-size: 14px; }

	&__text {
		font-size: 13px; }

	&--primary {

		#{$this}__icon {
			background-color: #6BC80E; }

		#{$this}__body {
			background: rgba(#6BC80E, 0.05);
			color: #6BC80E; } }

	&--success {

		#{$this}__icon {
			background-color: $c-sec; }

		#{$this}__body {
			background: rgba(0, 38, 99, 0.05);
			color: $c-sec; } }

	&--danger {

		#{$this}__icon {
			background-color: $red; }

		#{$this}__body {
			background: rgba(209, 0, 21, 0.05);
			color: $red; } }

	&--warning {

		#{$this}__icon {
			background-color: $c-main; }

		#{$this}__body {
			background: rgba(0, 155, 187, 0.05);
			color: $c-main; } }

	&--notice {

		#{$this}__icon {
			background-color: #5EA6E2; }

		#{$this}__body {
			background: rgba(#5EA6E2, 0.05);
			color: #5EA6E2; } }

	&--orange {

		#{$this}__icon {
			background-color: #EF6314; }

		#{$this}__body {
			background: rgba(#EF6314, 0.05);
			color: #EF6314; } } }
