.downloads {
	margin-bottom: var(--marginLg);

	&-head {
		margin-bottom: 30px;
		p {
			text-align: center;
			font-weight: 350;
			line-height: 145%;
			color: rgba(6, 14, 65, 1);
			@include media($sm) {
				text-align: left; }
			&:not(:last-child) {
				margin-bottom: 15px; } } }
	&-list {
		display: flex;
		flex-wrap: wrap;
		gap: 19px;
		@include media($lg) {
			gap: 12px; }
		@include media($sm) {
			gap: 25px; } }
	&__item {
		width: calc(33.33% - (19px / 3 * 2));
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 10px;
		padding: 40px 35px 45px;
		text-align: center;
		transition: .3s;
		@include media($lg) {
			width: calc(33.33% - (12px / 3 * 2));
			padding: 40px 22px 45px; }
		@include media($sm) {
			width: 100%;
			padding: 40px 40px 45px; }
		&:hover {
			box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.3); }
		&-icon {
			max-width: 68px;
			margin: 0 auto 30px;
			img {
				width: 100% !important;
				height: auto!important; } }
		&-title {
			margin-bottom: 10px;
			font-size: 20px;
			font-weight: bold;
			color: #060E41;
			@include media($lg) {
				font-size: 18px; }
			@include media($sm) {
				font-size: 20px; } }
		&-descr {
			font-size: 14px;
			font-weight: 350;
			line-height: 145%;
			margin-bottom: 25px;
			color: rgba(6, 14, 65, 1);

			@include media($lg) {
				font-size: 13px;
				max-width: 206px;
				margin: 0 auto 25px; }
			@include media($sm) {
				font-size: 14px;
				max-width: 100%; } }
		&-action {
			text-align: center;
			a {
				color: #35C8C7;
				font-size: 14px;
				font-weight: bold;
				text-transform: uppercase;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				padding-right: 0;

				.icon {
					width: 12px;
					height: 12px;
					fill: #35C8C7;
					top: 0;
					right: 0;
					left: 0;
					margin-top: -3px;
					margin-left: 10px;
					position: relative; } } } } }

