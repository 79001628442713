.privareserver {
	margin-bottom: var(--marginLg);

	&__inner {}

	&__descr {
		font-weight: 350;
		color: rgba(6, 14, 65, 1);
		text-align: center;
		line-height: 145%;
		margin-bottom: 35px;
		@include media($lg) {
			margin-bottom: 30px; }
		@include media($sm) {
			text-align: left; } }

	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		margin-bottom: 40px;
		float-wrap: wrap;
		@include media($lg) {
			gap: 10px; }
		@include media($sm) {
			gap: 0;
			margin-bottom: 35px; }


		&-item {
			background: #FFFFFF;
			box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
			border-radius: 10px;
			flex: 1;
			padding: 35px 24px 45px;
			@include media($lg) {
				padding: 30px 20px 35px; }
			@include media($sm) {
				width: 100%;
				flex: inherit;
				margin-bottom: 20px;
				padding: 35px 57px 40px;
				&:last-child {
					margin-bottom: 0; } }

			&-title {
				font-size: 22px;
				font-weight: bold;
				line-height: 100%;
				color: $c-bg;
				text-align: center;
				margin-bottom: 5px;
				@include media($lg) {
					font-size: 18px; }
				@include media($sm); }


			&-desc {
				font-weight: 350;
				line-height: 100%;
				color: rgba(6, 14, 65, 1);
				text-align: center;
				@include media($lg) {
					font-size: 13px; }
				@include media($sm) {
					font-size: 14px; } }

			&-pic {
				margin-bottom: 20px;
				@include media($sm) {
					margin-bottom: 25px; }
				img {
					width: 100% !important;
					height: auto !important;
					@include media($lg) {
						max-width: 140px;
						margin: 0 auto; }
					@include media($sm) {
						max-width: 230px; } } } } }

	&__footer {
		margin-bottom: 30px;
		text-align: center;
		color: rgba(6, 14, 65, 1);
		font-size: 16px;
		@include media($lg) {
			margin-bottom: 46px;
			font-size: 14px; }
		@include media($sm) {
			margin-bottom: 45px; }

		b {
			font-weight: bold; } }


	&__item {
		position: relative;
		padding: 35px 40px 40px;
		background: #EAF9F9;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		flex: 1;
		@include media($lg) {
			padding: 30px 41px 40px;
			align-items: flex-start;
			align-content: flex-start; }
		@include media($sm) {
			padding: 30px 25px 55px;
			flex: inherit;
			width: 100%;
			&:not(:last-child) {
				margin-bottom: 20px; } }

		&-pic {
			width: 32%;
			@include media($lg) {
				width: 100%;
				margin-bottom: 25px; }
			@include media($sm) {
				margin-bottom: 20px; }

			img {
				width: auto !important;
				height: auto !important;
				@include media($lg) {
					width: 100% !important;
					max-width: 250px;
					margin: 0 auto; }
				@include media($sm) {
					max-width: 230px; } } }

		&-body {
			padding-left: 20px;
			width: 68%;
			@include media($lg) {
				padding-left: 0;
				width: 100%; }

			.zag {
				font-weight: bold;
				font-size: 26px;
				color: $c-bg;
				line-height: 100%;
				margin-bottom: 10px;
				@include media($lg) {
					text-align: center;
					font-size: 24px;
					margin-bottom: 15px; }
				@include media($sm) {
					margin-bottom: 15px; } }

			p {
				font-weight: 350;
				font-size: 14px;
				line-height: 145%;
				color: rgba(6, 14, 65, 1);
				@include media($lg) {
					font-size: 16px; } } } }


	.bottom-elems {
		display: flex;
		gap: 20px;
		@include media($sm) {
			gap: 0;
			flex-wrap: wrap; } } }
