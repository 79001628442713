.filter-drop {
	display: flex;
	align-items: center;

	&__title {
		font-size: 16px;
		font-weight: bold;
		color: $c-bg;
		padding-right: 15px; }

	.dropdown {}

	.dropdown-toggle {
		position: relative;
		padding-left: 0;
		padding-right: 15px;
		background-color: $trp;
		border: none;
		font-family: $font-1;
		color: rgba(6, 14, 65, 0.5);
		cursor: pointer;

		&:focus {
			outline: none; }

		&:after {
			position: absolute;
			width: 8px;
			height: 8px;
			top: 6px;
			right: 1px;
			margin: 0;
			border: none;
			border-bottom: 2px solid rgba(6, 14, 65, 0.5);
			border-right: 2px solid rgba(6, 14, 65, 0.5);
			transform: rotate(45deg); } }


	.dropdown-menu {
		min-width: 204px;
		padding: 20px 0;
		border-radius: 0;
		max-height: 300px;
		overflow: auto; }

	.dropdown-item {
		padding-left: 35px;
		font-size: 14px;
		font-weight: 600;
		font-family: $font-2;
		color: $c-bg;

		&:not(:last-child) {
			margin-bottom: 25px; } } }
