.jq-checkbox,
.jq-radio {
	vertical-align: -4px;
	width: 16px;
	height: 16px;
	margin: 0 4px 0 0;
	border: 1px solid #C3C3C3;
	background: linear-gradient(#FFF, #E6E6E6);
	box-shadow: 0 1px 1px rgba(0,0,0,.05), inset -1px -1px #FFF, inset 1px -1px #FFF;
	cursor: pointer;
}
.jq-checkbox.focused,
.jq-radio.focused {
	border: 1px solid #08C;
}
.jq-checkbox.disabled,
.jq-radio.disabled {
	opacity: .55;
}
.jq-checkbox {
	border-radius: 3px;
}
.jq-checkbox.checked .jq-checkbox__div {
	width: 8px;
	height: 4px;
	margin: 3px 0 0 3px;
	border-bottom: 2px solid #666;
	border-left: 2px solid #666;
	-webkit-transform: rotate(-50deg);
	transform: rotate(-50deg);
}
.jq-radio {
	border-radius: 50%;
}
.jq-radio.checked .jq-radio__div {
	width: 10px;
	height: 10px;
	margin: 3px 0 0 3px;
	border-radius: 50%;
	background: #777;
	box-shadow: inset 1px 1px 1px rgba(0,0,0,.7);
}
.jq-file {
	width: 270px;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
}
.jq-file input {
	cursor: pointer;
}
.jq-file__name {
	box-sizing: border-box;
	width: 100%;
	height: 34px;
	padding: 0 80px 0 10px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1;
	font: 14px/32px Arial, sans-serif;
	color: #333;
}
.jq-file__browse {
	position: absolute;
	top: 1px;
	right: 1px;
	padding: 0 10px;
	border-left: 1px solid #CCC;
	border-radius: 0 4px 4px 0;
	background: linear-gradient(#FFF, #E6E6E6);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/32px Arial, sans-serif;
	color: #333;
	text-shadow: 1px 1px #FFF;
}
.jq-file:hover .jq-file__browse {
	background: linear-gradient(#F6F6F6, #E6E6E6);
}
.jq-file:active .jq-file__browse {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-file.focused .jq-file__name {
	border: 1px solid #5794BF;
}
.jq-file.disabled,
.jq-file.disabled .jq-file__name,
.jq-file.disabled .jq-file__browse {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-number {
	position: relative;
	vertical-align: middle;
	padding: 0 36px 0 0;
}
.jq-number__field {
	width: 100px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
}
.jq-number__field:hover {
	border-color: #B3B3B3;
}
.jq-number__field input {
	box-sizing: border-box;
	width: 100%;
	padding: 8px 9px;
	border: none;
	outline: none;
	background: none;
	font: 14px Arial, sans-serif;
	color: #333;
}
.jq-number__spin {
	position: absolute;
	top: 0;
	right: 0;
	width: 32px;
	height: 14px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: linear-gradient(#FFF, #E6E6E6);
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-shadow: 1px 1px #FFF;
	cursor: pointer;
}
.jq-number__spin.minus {
	top: auto;
	bottom: 0;
}
.jq-number__spin:hover {
	background: linear-gradient(#F6F6F6, #E6E6E6);
}
.jq-number__spin:active {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-number__spin:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 11px;
	width: 0;
	height: 0;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #999;
	border-left: 5px solid transparent;
}
.jq-number__spin.minus:after {
	top: 5px;
	border-top: 5px solid #999;
	border-right: 5px solid transparent;
	border-bottom: none;
	border-left: 5px solid transparent;
}
.jq-number__spin.minus:hover:after {
	border-top-color: #000;
}
.jq-number__spin.plus:hover:after {
	border-bottom-color: #000;
}
.jq-number.focused .jq-number__field {
	border: 1px solid #5794BF;
}
.jq-number.disabled .jq-number__field,
.jq-number.disabled .jq-number__spin {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-number.disabled .jq-number__spin:after {
	border-bottom-color: #AAA;
}
.jq-number.disabled .jq-number__spin.minus:after {
	border-top-color: #AAA;
}















.jq-selectbox {
	display: block;
	cursor: pointer;

	&.opened {

		.jq-selectbox__trigger-arrow {
			top: 18px;
			transform: rotate(135deg)
		}
	}

	&.focused {

		.jq-selectbox__trigger {
			background-color: $c-sec;
		}

		.jq-selectbox__trigger-arrow {
			border-color: #fff;
		}
	}
}

.jq-selectbox__select {
	height: 42px;
	padding: 0 55px 0 12px;
	font-family: $font-1;
	font-size: 12px;
	line-height: 42px;
	color: $c-text;
	border: 1px solid #E7E7E7;
	@include transish;

	&:hover {

		.jq-selectbox__trigger {
			background-color: $c-main;
		}

		.jq-selectbox__trigger-arrow {
			border-color: #fff;
		}
	}
}

.jq-selectbox.disabled .jq-selectbox__select {
	border: 1px solid #ccc;
}
.jq-selectbox__select-text {
	display: block;
}
.jq-selectbox .placeholder {
	color: #888;
}
.jq-selectbox__trigger {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	@include transish();
}
.jq-selectbox__trigger-arrow {
	position: absolute;
	top: 14px;
	right: 15px;
	width: 6px;
	height: 6px;
	border-left: 2px solid $c-sec;
	border-bottom: 2px solid $c-sec;
	transform: rotate(-45deg);
	box-sizing: initial;
	@include transish();
}

.jq-selectbox__dropdown {
	width: 100%;
	padding: 0px;
	background: #fff;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	overflow: hidden;

	ul {
		max-height: 300px;
		// margin-top: -28px !important;
		margin-bottom: 0 !important;
		padding: 30px !important;

		&::-webkit-scrollbar {
			width: 8px;
			background-color: #F5F5F5;
			border-radius: 0px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #E0E0E0;
			border-radius: 0px;
			cursor: pointer;
		}

		// &::-webkit-scrollbar-button {
		// 	background-color: $c-sec;
		// 	height: 8px;
		// }
	}

	li {
		padding: 0 !important;
		font-size: 12px !important;
		line-height: 1.7 !important;
		color: $c-text !important;
		letter-spacing: 0.03em;

		&:before {
			display: none !important;
		}

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&:first-child {
			display: none;
		}
	}
}













.jq-selectbox__search {
	margin: 5px;
}
.jq-selectbox__search input {
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 5px 27px 6px 8px;
	border: 1px solid #CCC;
	border-radius: 3px;
	outline: none;
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==') no-repeat 100% 50%;
	box-shadow: inset 1px 1px #F1F1F1;
	color: #333;
}
.jq-selectbox__not-found {
	margin: 5px;
	padding: 5px 8px 6px;
	background: #F0F0F0;
	font-size: 13px;
}
.jq-selectbox ul {
	margin: 0;
	padding: 0;
}
.jq-selectbox li {
	min-height: 18px;
	padding: 5px 10px 6px;
	color: #231F20;
}
.jq-selectbox li.selected {
	color: $c-main !important;
}
.jq-selectbox li:hover {
	color: $c-main !important;
}
.jq-selectbox li.disabled {
	color: #AAA;
}
.jq-selectbox li.disabled:hover {
	background: none;
}
.jq-selectbox li.optgroup {
	font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}
.jq-selectbox li.option {
	padding-left: 25px;
}
.jq-select-multiple {
	box-sizing: border-box;
	padding: 1px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/18px Arial, sans-serif;
	color: #333;
	cursor: default;
}
.jq-select-multiple.focused {
	border: 1px solid #5794BF;
}
.jq-select-multiple.disabled {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-select-multiple ul {
	margin: 0;
	padding: 0;
}
.jq-select-multiple li {
	padding: 3px 9px 4px;
	list-style: none;
}
.jq-select-multiple li:first-child {
	border-radius: 3px 3px 0 0;
}
.jq-select-multiple li:last-child {
	border-radius: 0 0 3px 3px;
}
.jq-select-multiple li.selected {
	background: #08C;
	color: #FFF;
}
.jq-select-multiple li.disabled {
	color: #AAA;
}
.jq-select-multiple li.optgroup {
	font-weight: bold;
}
.jq-select-multiple li.option {
	padding-left: 25px;
}
.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
	background: #CCC;
	color: #FFF;
}
input[type='email'].styler,
input[type='password'].styler,
input[type='search'].styler,
input[type='tel'].styler,
input[type='text'].styler,
input[type='url'].styler,
textarea.styler {
	padding: 8px 9px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px Arial, sans-serif;
	color: #333;
}
input[type='search'].styler {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
textarea.styler {
	overflow: auto;
}
input[type='email'].styler:hover,
input[type='password'].styler:hover,
input[type='search'].styler:hover,
input[type='tel'].styler:hover,
input[type='text'].styler:hover,
input[type='url'].styler:hover,
textarea.styler:hover {
	border-color: #B3B3B3;
}
input[type='email'].styler:hover:focus,
input[type='password'].styler:hover:focus,
input[type='search'].styler:hover:focus,
input[type='tel'].styler:hover:focus,
input[type='text'].styler:hover:focus,
input[type='url'].styler:hover:focus,
textarea.styler:hover:focus {
	border-color: #CCC;
	border-top-color: #B3B3B3;
	outline: none;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}
button.styler,
input[type='button'].styler,
input[type='submit'].styler,
input[type='reset'].styler {
	overflow: visible;
	padding: 8px 11px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	outline: none;
	background: linear-gradient(#FFF, #E6E6E6);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px Arial, sans-serif;
	color: #333;
	text-shadow: 1px 1px #FFF;
	cursor: pointer;
}
button.styler.styler::-moz-focus-inner,
input[type='button'].styler.styler::-moz-focus-inner,
input[type='submit'].styler.styler::-moz-focus-inner,
input[type='reset'].styler.styler::-moz-focus-inner {
	padding: 0;
	border: 0;
}
button.styler:not([disabled]):hover,
input[type='button'].styler:not([disabled]):hover,
input[type='submit'].styler:not([disabled]):hover,
input[type='reset'].styler:not([disabled]):hover,
input[type='reset'].styler:hover {
	background: linear-gradient(#F6F6F6, #E6E6E6);
}
button.styler:active,
input[type='button'].styler:active,
input[type='submit'].styler:active,
input[type='reset'].styler:active {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
button.styler[disabled],
input[type='button'].styler[disabled],
input[type='submit'].styler[disabled] {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}