.link-action {
	position: relative;
	display: inline-block;
	padding-left: 22px;
	font-size: 14px;


	text-decoration: none;
	transition: color 0.3s ease;
	line-height: 1;

	@include media($md) {
		font-size: 12px; }

	&:hover {
		text-decoration: none;

		.icon {
			transform: scale(1.2); } }

	.icon {
		position: absolute;
		top: 0;
		left: 0;
		@include sizeblock(14px);
		fill: $c-text;
		transition: transform 0.3s ease, fill 0.3s ease;

		@include media($md) {
			top: -2px; } } }
