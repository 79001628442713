.link-arrow {
	position: relative;
	display: inline-block;
	padding-right: 40px;
	color: $c-main;
	font-size: 16px;
	font-weight: 700;
	line-height: 1;
	letter-spacing: -0.04em;
	text-transform: uppercase;

	&:hover {
		text-decoration: none;

		.icon {
			transform: rotate(180deg) translate(-5px, 0); } }

	.icon {
		position: absolute;
		right: 0;
		top: -9px;
		width: 30px;
		height: 30px;
		fill: #AFAFAF;
		@include transish;
		transform: rotate(180deg);
		will-change: transform; }

	&._color {
		display: inline-flex;
		align-items: center;
		font-size: 13px;
		padding-right: 0;
		line-height: 1.4;
		.icon {
			position: relative;
			right: auto;
			top: auto;
			width: 12px;
			height: 12px;
			fill: $c-main;
			margin: 0 7px;
			margin-top: -5px; } } }
