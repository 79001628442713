.vacancies {
	margin-bottom: var(--marginLg);
	&__title {
		text-align: center; }
	&-filter {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px; }
	&-list {
		display: flex;
		flex-wrap: wrap;
		gap: 13px;
		@include media($sm) {
			gap: 25px; } } }


.vacancy-card {
	padding: 40px 32px 60px;
	position: relative;
	border-radius: 20px;
	background-color: #fff;
	box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
	transition: .3s;
	width: calc(25% - (13px / 4 * 3));
	text-decoration: none !important;
	@include media($lg) {
		width: calc(33.33% - (13px / 4 * 3));
		padding-bottom: 68px; }
	@include media($sm) {
		width: 100%;
		padding: 40px 45px 75px; }
	&:hover {
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.25); }
	&__img {
		img {
			max-width: 160px;
			width: 100% !important;
			height: auto!important;
			margin: 0 auto 20px;
			@include media($sm) {
				max-width: 220px;
				margin-bottom: 25px; } } }
	&__content {
		text-align: center; }

	&__title {
		text-align: center;
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 5px;
		color: #060E41;
		display: inline-block;
		@include media($sm) {
			font-size: 20px;
			margin-bottom: 10px; } }
	p {
		font-weight: 350;
		font-size: 13px;
		color: rgba(6, 14, 65, 1);
		@include media($lg) {
			font-size: 14px; } }


	&__date {
		font-size: 13px;
		line-height: 145%;
		font-weight: 350;
		color: rgba(6, 14, 65, 1);
		@include media($sm) {
			font-size: 14px; } }

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 0 25px 20px;
		width: 100%;
		@include media($sm) {
			padding: 0 30px 30px; } }

	.arrow-link {
		.icon {
			transform: rotate(180deg);
			width: 15px;
			height: 15px;
			fill: rgba(6, 14, 65, 0.5); } } }
