@import '../../assets/css/common/components-import';



.share {

	.ya-share2 {
		position: absolute;
		width: 0;
		height: 0;
		line-height: 0;
		visibility: hidden;
		opacity: 0; }

	&__list {
		display: flex; }

	&__item {
		width: 30px;
		height: 30px;
		margin-right: 5px;
		padding: 2px;
		border-radius: 2px;
		background-color: $c-main;
		cursor: pointer;
		user-select: none;

		.icon {
			display: block;
			width: 100%;
			fill: #fff; } } }
