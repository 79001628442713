.text-block {
	margin-bottom: var(--marginLg);

	&__wrp {
		background: #FFFFFF;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 10px;
		padding: 40px 70px 50px;
		@include media($lg) {
			padding: 40px 45px 50px; }
		@include media($sm) {
			padding: 40px 30px 50px; }
		& + .text-block__wrp {
			margin-top: 20px; }
		&._center, ._center {
			text-align: center;
			@include media($sm) {
				text-align: left; } }
		p {
			font-size: 16px;
			font-weight: 350;
			color: rgba(6, 14, 65, 1);
			line-height: 1.45;
			@include media($lg) {
				font-size: 14px; }
			&:not(:last-child) {
				margin-bottom: 15px; } }
		h3 {
			margin: 30px 0 25px;
			font-size: 22px;
			font-weight: bold;
			line-height: 130%;
			color: #060E41;
			&:first-child {
				margin-top: 0; }
			&._sm {
				margin: 25px 0 15px;
				@include media($sm) {
					font-size: 22px; } }
			&._mt0 {
				margin-top: 0; }
			@include media($lg) {
				font-size: 16px; }
			@include media($sm) {
				margin: 25px 0 15px;
				text-align: center; } }

		img {
			margin: 0 auto 25px;
			height: auto!important;

			@include media($sm) {
				min-width: 225px; } }

		.wecan__body {
			padding: 0;
			margin-bottom: 25px;
			@include media($lg) {
				margin-bottom: 15px; }
			@include media($sm) {
				margin-top: 35px; } }
		.wecan__item {
			line-height: 135%;
			@include media($lg) {
				font-size: 14px; }
			@include media($sm) {
				margin-bottom: 20px;
				max-width: 285px; } }
		.list-style {
			font-weight: 350;
			margin-bottom: 28px;
			@include media($lg) {
				margin-bottom: 20px; }

			li {
				font-size: 16px;
				text-align: left;
				margin-bottom: 7px;
				@include media($lg) {
					margin-bottom: 10px;
					font-size: 14px; }
				@include media($sm) {
					margin-bottom: 5px; } }
			&._w33 {
				padding: 0 100px;
				display: flex;
				flex-wrap: wrap;
				@include media($lg) {
					padding: 0 35px; }
				li {
					width: 33.33%;
					@include media($sm) {
						width: 100%;
						max-width: 207px;
						margin-left: auto;
						margin-right: auto; } } }

			&._w100 {
				li {
					width: 100%;
					max-width: 540px;
					margin-left: auto;
					margin-right: auto;
					@include media($sm) {
						max-width: 270px; } } }
			&._wAuto {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				@include media($sm) {
					flex-direction: column; }
				li {
					@include media($sm) {
						max-width: 130px;
						width: 100%;
						margin-left: auto;
						margin-right: auto; }
					&:not(:last-child) {
						margin-right: 25px;
						@include media($sm) {
							margin-right: auto; } } } }


			&._mb0 {
				margin-bottom: 0; } } } }



.container {
	.container {
		padding: 0!important; } }
