.calc {
	margin-bottom: 60px;

	&__inner {
		display: flex;
		align-items: center;
		overflow: hidden;

		@include media($md) {
			display: block; } }

	&__pic {
		flex: 1 0 600px;
		max-width: 600px;

		@include media($lg) {
			flex: 1 0 300px;
			max-width: 300px; }

		@include media($md) {
			margin: 0 auto; } }

	&__content {
		margin-left: 30px;
		padding: 20px 0;

		@include media($md) {
			margin-left: 0; } }

	&__text {

		&:not(:last-child) {
			margin-bottom: 16px; } } }
