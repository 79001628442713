.faq-list {
	margin-bottom: var(--marginLg);
	&__list {} }



.accordion {
	&__item {
		&:not(:last-child) {
			margin-bottom: 20px;
			@include media($lg) {
				margin-bottom: 15px; } }
		&._open {
			.accordion__item-zag {
				box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.3);
				.arrow {
					transform: rotate(180deg); } } }
		&-zag {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px 50px 25px;
			border-radius: 10px;
			box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
			color: rgba(6, 14, 65, 0.8);
			line-height: 145%;
			cursor: pointer;
			background-color: #fff;
			transition: .3s;

			@include media($lg) {
				padding: 20px 30px 25px;
				font-size: 14px; }
			@include media($sm) {
				padding: 20px 23px 25px; }
			&:hover, &._open {
				box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.3); }


			span, h3 {
				max-width: calc(100% - 30px); }

			.arrow {
				transition: .3s;

				.icon {
					fill: #35C8C7;
					width: 30px;
					height: 30px; } } }

		&-content {
			padding: 40px 45px;
			display: none;
			@include media($lg) {
				padding: 35px 30px 40px;
				font-size: 14px; }
			@include media($sm) {
				padding: 30px 23px 45px; }
			p {
				font-weight: 350;
				line-height: 145%;
				color: rgba(6, 14, 65, 1);
				&:not(:last-child) {
					margin-bottom: 15px;
					@include media($sm) {
						margin-bottom: 10px; } } } } } }

