.iotdevice {
	margin-bottom: var(--marginLg);
	.row {
		margin: 0;
		display: inline-flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%; }
	.section-title {
		width: 100%; }

	&__item {
		position: relative;
		padding: 40px 40px 50px;
		background-color: #EAF9F9;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 10px;
		flex: 1;
		@include media($lg) {
			flex: inherit;
			width: 100%;
			padding: 30px 30px 50px; }
		@include media($sm);


		&-title {
			width: 56%;
			@include media($lg) {
				width: 47%; }
			@include media($sm) {
				width: 100%; }
			&-text {
				font-size: 24px;
				line-height: 1;
				font-weight: 700;
				color: $c-bg;
				margin-bottom: 10px;
				@include media($sm) {
					text-align: center; } }


			&-list {
				@include media($sm) {
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					margin: 0 auto; }
				li {
					position: relative;
					padding-left: 16px;
					color: rgba(6, 14, 65, 1);
					line-height: 100%;
					&:not(:last-child) {
						margin-bottom: 7px; }

					&:after {
						@include pseudo;
						left: 0;
						top: 0.4em;
						width: 7px;
						height: 7px;
						border-radius: 50%;
						background-color: $c-main; } } } }

		&-top {
			display: flex;
			align-items: center;
			width: 100%;
			margin-bottom: 20px;
			@include media($sm) {
				flex-wrap: wrap; } }

		&-pic {
			width: 44%;
			text-align: center;
			padding: 0 10px;
			@include media($lg) {
				width: 53%;
				padding-right: 25px; }
			@include media($sm) {
				width: 100%;
				padding: 0;
				max-width: 200px;
				margin: 0 auto 20px; }
			img {
				margin: 0 auto;
				width: auto!important;
				height: auto!important;
				@include media($lg) {
					margin-right: 0; }
				@include media($sm) {
					width: 100% !important; } } }


		&._w100 {
			width: 100%;
			flex: inherit;
			padding: 55px 110px 65px;
			display: inline-flex;
			flex-wrap: wrap;
			align-items: center;
			@include media($lg) {
				padding: 50px 36px 60px 30px; }
			@include media($sm) {
				padding: 30px 30px 50px; }
			.iotdevice__item-top {
				width: 32%;
				margin-bottom: 0;
				@include media($lg) {
					width: 38%; }
				@include media($sm) {
					width: 100%; } }

			.iotdevice__item-pic {
				width: 100%;
				padding: 0; }
			.iotdevice__body {
				padding-left: 40px;
				width: 68%;
				@include media($lg) {
					width: 62%; }
				@include media($sm) {
					width: 100%;
					padding-left: 0; }
				.iotdevice__texts p,
				.iotdevice__props-item-text {
					font-size: 16px; } } } }

	&__body {}

	&__texts {
		margin-bottom: 20px;
		@include media($lg) {
			margin-bottom: 25px; }
		p {
			font-weight: 350;
			font-size: 14px;
			line-height: 145%;
			color: rgba(6, 14, 65, 1);
			@include media($lg) {
				font-size: 16px; } } }

	&__props {
		&-item {
			color: rgba(6, 14, 65, 1);
			&:not(:last-child) {
				margin-bottom: 20px;
				@include media($lg) {
					margin-bottom: 25px; } }

			&-title {
				font-weight: bold;
				margin-bottom: 10px;
				font-size: 18px;
				line-height: 110%; }
			&-text {
				font-size: 14px;
				font-weight: 350;
				@include media($lg) {
					font-size: 16px; } } } }


	&__footer {
		position: relative;
		padding: 60px 30px;
		background-color: #EAF9F9;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		border-radius: 10px;
		width: 100%;

		@include media($lg) {
			padding: 20px 40px; }

		&-content {
			max-width: 550px;
			margin: 0 auto;
			text-align: center; }

		&-title {
			font-size: 26px;
			font-weight: 700;
			color: $c-bg;
			line-height: 1; }

		&-descr {
			margin-top: 15px;
			color: $c-bg; }

		&-list {
			margin-top: 15px; }

		&-item {

			&:not(:last-child) {
				margin-bottom: 15px; }

			&-title {
				font-weight: 700; }

			&-descr {} }

		&-pic {
			position: absolute;
			left: 30px;
			@include hcenter;
			@include sizeblock(195px);

			@include media($lg) {
				position: static;
				left: auto;
				top: auto;
				@include sizeblock(120px);
				transform: none;
				margin: 0 auto; } } }

	&._inverse {
		.iotdevice__item {
			padding: 50px 55px 60px;
			transition: .3s;
			text-decoration: none !important;
			span:not(.link-arrow) {
				display: block; }
			* {
				text-decoration: none !important; }
			@include media($lg) {
				padding: 40px 40px 50px;
				align-content: flex-start; }
			@include media($sm) {
				padding: 40px 29px 50px; }
			&:hover {
				box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.25); }
			&:not(:last-child) {
				margin-bottom: 45px;
				@include media($lg) {
					margin-bottom: 0; }
				@include media($sm) {
					margin-bottom: 40px; } }
			&:nth-child(even) {
				background-color: #fff;
				.iotdevice__body {
					order: 1;
					padding-left: 0;
					padding-right: 30px;
					@include media($lg) {
						order: 3;
						padding-right: 0; } }
				.iotdevice__item-top {
					order: 2; } }
			&._w100 {
				@include media($lg) {
					width: calc(50% - 10px); }
				@include media($sm) {
					width: 100%; }
				.iotdevice__props-item {
					&-text {
						font-size: 14px; } }
				.iotdevice__body .iotdevice__texts p {
					@include media($lg) {
						font-size: 14px; } } }

			&-top {
				width: 34%;
				@include media($lg) {
					width: 100%; }
				.iotdevice__item-pic {
					@include media($lg) {
						max-width: 160px;
						margin: 0 auto 25px; }
					@include media($sm) {
						max-width: 225px; } } } }

		.iotdevice {
			&__body {
				padding-left: 30px;
				width: 66%;
				@include media($lg) {
					width: 100%;
					padding: 0 0 38px; }

				.link-arrow {
					margin-top: 25px;
					@include media($lg) {
						position: absolute;
						left: 40px;
						bottom: 50px; }
					@include media($sm) {
						left: 29px; } } }
			&__texts {
				@include media($lg) {
					margin-bottom: 20px; }
				p {
					&:not(:last-child) {
						margin-bottom: 10px; } } }
			&__props-item {
				max-width: 300px;
				&:not(:last-child) {
					@include media($lg) {
						margin-bottom: 20px; } }
				&-text {
					font-size: 14px;
					line-height: 145%; }
				&-title {
					font-size: 16px; } }

			&__item-title {
				width: 100%;
				&-text {
					font-size: 22px;
					margin-bottom: 20px;
					@include media($lg) {
						text-align: center;
						margin-bottom: 15px; } } } } } }



