.preloader {
	position: relative;
	pointer-events: none;

	&:after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: #fff;
		opacity: .5;
		z-index: 1;
	}

	&:before {
		content: '';
		position: absolute;
		width: 40px;
		height: 40px;
		border-radius: 100%;
		border: 5px solid #ccc;
		border-top-color: $c-main;
		left: 50%;
		top: 50%;
		margin-left: -20px;
		margin-top: -20px;
		transform-origin: 50% 50%;
		animation-name: rotate;
		animation-duration: 600ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		z-index: 100;
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
