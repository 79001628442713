.file-icon {
	position: relative;
	width: 33px; //24
	height: 44px; //34

	&__ext {
		position: absolute;
		@include wcenter;
		bottom: 4px;
		color: #fff;
		text-transform: uppercase;
		font-weight: 700;
		color: #fff;
		font-size: 8px; } //6

	.icon {
		@include sizeblock(100%); }

	&--pdf {

		.icon {
			fill: #F50000; } }

	&--doc {

		.icon {
			fill: #006CBF; } }

	&--xls {

		.icon {
			fill: #1F7246; } }

	&--file {

		.icon {
			fill: #ccc; } } }
