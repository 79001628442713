.link-icon {
	position: relative;

	&:hover {

		.link-icon__icon {

			.icon {
				fill: $c-main; } } }

	&__icon {
		position: absolute;
		top: 2px;
		left: 0px;

		@include media($lg) {
			top: 3px; }

		.icon {
			display: block;
			@include sizeblock(18px);
			fill: $c-main;
			transition: fill 0.3s ease; } }

	&__link {
		display: inline-block;
		padding-left: 38px;
		font-size: 12px;
		color: $c-text; }

	a.link-icon__link {
		color: $c-main; } }
