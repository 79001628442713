.news-list {
	margin-bottom: var(--marginLg);

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 16px;
		@include media($lg) {
			gap: 15px; }
		@include media($sm) {
			gap: 25px 0; }
		.trand-card {
			width: calc(25% - (16px / 4 * 3));
			box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
			@include media($lg) {
				width: calc(33% - (15px / 4 * 3)); }
			@include media($sm) {
				width: 100%; }
			&__body {
				padding: 20px 20px 60px;
				@include media($sm) {
					padding: 25px 20px  60px; }
				.date {
					font-size: 11px;
					line-height: 120%;
					@include media($sm) {
						font-size: 14px; } }
				.simple-ar-icon {
					span {
						font-size: 11px;
						@include media($sm) {
							font-size: 14px; } }
					.icon {
						margin: 0;
						margin-top: -3px;
						margin-left: 10px; } } }

			&__title {
				font-size: 20px;
				@include media($sm) {
					font-size: 22px; } }
			&__descr {
				font-size: 15px;
				font-weight: 350;
				margin-bottom: 0;
				@include media($sm) {
					font-size: 18px; } }
			&__action {
				width: calc(100% - 40px);
				left: 20px;
				bottom: 15px; } }
		@include media($lg) {
			justify-content: center; } }
	&__pagination {

		.paginations {
			padding-top: 50px;
			@include media($sm) {
				padding-top: 60px; } } } }



