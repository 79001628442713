.devcenter {
	margin-bottom: var(--marginLg);

	&__body {
		display: flex;
		overflow: hidden;
		border-radius: 10px;
		box-shadow: 0px 12px 24px rgba(114, 120, 213, 0.2);
		@include media($sm) {
			flex-wrap: wrap; } }

	&__pic {
		width: 50%;
		@include media($sm) {
			width: 100%; }
		img {
			width: 100%!important;
			height: 100%!important;
			object-fit: cover; } }

	&__content {
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
		width: 50%;
		padding: 50px 34px 40px 65px;
		@include media($lg) {
			padding: 25px 25px 13px 25px; }
		@include media($sm) {
			width: 100%;
			padding: 30px 30px 53px; } }
	&__item {
		width: 50%;
		text-align: center;
		margin-bottom: 20px;
		@include media($lg) {
			width: 60%;
			&:nth-child(n+2):nth-child(even) {
				width: 40%; } }
		@include media($sm) {
			width: 100% !important;
			max-width: 190px;
			margin: 0 auto 30px;
			&:last-child {
				margin-bottom: 0; } }
		&-title {
			font-size: 24px;
			font-weight: bold;
			line-height: 1;
			margin-bottom: 10px;
			color: #060E41;
			@include media($sm) {
				margin-bottom: 15px; } }
		a {
			display: block;
			line-height: 1;
			font-weight: 350;
			color: rgba(6, 14, 65, 1);
			@include media($lg) {
				font-size: 14px; }
			&:not(:last-child) {
				margin-bottom: 10px; }
			&[href^="https:"] {
				text-decoration: underline; } }
		span {
			display: block;
			line-height: 1;
			font-weight: 350;
			color: rgba(6, 14, 65, 1);
			@include media($lg) {
				font-size: 14px; }
			&:not(:last-child) {
				margin-bottom: 10px; }
			b {
				font-size: 18px;
				font-weight: bold;
				color: rgba(6, 14, 65, 0.9);
				@include media($lg) {
					font-size: 16px; }
				@include media($sm) {
					margin-bottom: 15px; } } } } }



